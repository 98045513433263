import axios from "axios";
import BaseService from "../config/baseService";
import { API_PAY, API_VERIFY_PAY, API_TAMARA_PAY } from "../config/webService";
import { currentLanguage } from "../util/common";

class HyperpayService extends BaseService {
  async getHyperpayInstance(data) {
    const response = await axios.post(API_PAY, data);
    return response && response.data;
  }

  async verifyPayment({ hyperpayId, orderId, appointmentId, subscribedSubscriptionId }) {
    const response = await axios.get(`${API_VERIFY_PAY}/${hyperpayId}`, {
      params: { orderId, appointmentId, subscribedSubscriptionId, lang: currentLanguage() }
    });
    return response && response.data;
  }

  // Tamara payment method
  async verifyTamaraPayment(orderId) {
    const response = await axios.get(`${API_TAMARA_PAY}/${orderId}`);
    return response && response.data;
  }
}

export default new HyperpayService();
