import React, { useEffect, useState } from "react";
import { currentLanguage, getQueryData, loadStorage } from "../../util/common";
import { Helmet } from "react-helmet";
import { logoSet } from "../../util/constants";
import {
  checkTamaraPaymentStatus,
  createOrder,
  getAppointmentPayload,
  getOrderPayload,
  langConfig
} from "../../util/addonsUtils";
import { useTranslation } from "react-i18next";
import { Loader, NotificationHelper, Breadcrumb } from "./";
import { useDispatch, useSelector } from "react-redux";
import { removeAllItems } from "../../redux/reducers/cartReducer";
import { SubscriptionService, HyperpayService, AppointmentService } from "../../services";
import { saveUser } from "../../redux/reducers/userReducer";
import { Modal } from "react-bootstrap";
import { useAppNavigation } from "../../hooks";

const PaymentProcessor = ({ show, hide }) => {
  const { t } = useTranslation();
  const {
    localeRoutes,
    toFastDrop,
    toNotFound,
    toShopCheckout,
    toSubscribeOffer,
    toSuccessScreen,
    toTrackYourProgress,
    toUrlWithData
  } = useAppNavigation();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.details);

  const draftVals = loadStorage("draftVals", "sessionStorage");
  const config = draftVals?.hyperpayConfig || {};
  const module = config.module;
  const lang = currentLanguage();
  const hyperpayId = getQueryData("id");
  const paymentStatus = getQueryData("paymentStatus");

  const [loader, setLoader] = useState(false);
  const [isPaying, setPaying] = useState(false);

  useEffect(() => {
    if (!draftVals) {
      toNotFound();
      return;
    } else if (paymentStatus) {
      proceedAfterTamaraPayment();
      return;
    } else if (hyperpayId) {
      startOrder();
      return;
    }

    generatePaymentForm();
  }, []);

  const generatePaymentForm = async () => {
    if (config.card === "TAMARA") return launchTamaraInstance();

    setLoader(true);
    try {
      const instance = await HyperpayService.getHyperpayInstance({
        amount: config.totalPrice.toString(),
        paymentBrand: config.card
      });
      injectPaymentFormInUI(instance || {});
    } catch (e) {
      console.error(e);
    } finally {
      const interval = setInterval(() => {
        const payBtn = document.getElementsByClassName("wpwl-button-pay")[0];
        if (payBtn) {
          payBtn.innerHTML =
            t("addons.payBtn") + " " + config.totalPrice + " " + t("addons.currency");
          clearInterval(interval);
          setLoader(false);
        }
      }, 1000);
    }
  };

  const injectPaymentFormInUI = ({ redirect }) => {
    const portalRoot = document.getElementById("portal-root");
    if (redirect && portalRoot) {
      const scriptDiv = document.createElement("div");
      scriptDiv.id = "payment-widget-dd";
      const script = document.createElement("script");
      script.src = redirect;
      scriptDiv.append(script);
      portalRoot.append(scriptDiv);
    }
  };

  const launchTamaraInstance = () =>
    (window.location.href = window.location.pathname + "?id=tamara");

  const startOrder = async () => {
    try {
      setPaying(true);
      let orderCheck = true;
      if (module === "appointment") {
        const appointmentPayload = getAppointmentPayload(draftVals);
        orderCheck = await AppointmentService.scheduleAppointment(appointmentPayload);
      }
      if (module === "subscription") orderCheck = await subscribeOffer();

      if (orderCheck) {
        const orderPayload = getOrderPayload({
          module,
          draftVals,
          references: orderCheck
        });
        const orderStatus = await createOrder({
          hyperpayId,
          orderPayload,
          handleFailedPayment,
          appointmentId: orderCheck?._id
        });
        if (orderStatus) return handleSuccessPayment(orderCheck?._id);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setPaying(false);
    }
  };

  const proceedAfterTamaraPayment = async () => {
    setPaying(true);
    const orderId = getQueryData("id");

    const status = await checkTamaraPaymentStatus(orderId);
    if (status) return handleSuccessPayment(status);

    if (paymentStatus === "failed" || paymentStatus === "canceled") handleFailedPayment();
  };

  const handleSuccessPayment = (appointmentId) => {
    if (module === "appointment") {
      saveUserInfo(draftVals?.appointment, appointmentId);
      NotificationHelper.success(t("addons.appointmentSuccessNotification"));
      const showExpress = draftVals?.activeStates?.activateForExpress;
      if (showExpress) return toFastDrop(appointmentId);
    }
    if (module === "eshop") dispatch(removeAllItems());
    if (module === "subscription") saveUserInfo(draftVals);

    toSuccessScreen({ from: module });
  };

  const handleFailedPayment = () => {
    goToOrderSummary();
    if (config.card === "TAMARA") return hide();
    generatePaymentForm();
  };

  const subscribeOffer = async () => {
    try {
      const result = await SubscriptionService.subscribeOffer(draftVals);
      return result.userSubscriptionId;
    } catch (e) {
      NotificationHelper.error("Subscription failed!");
      handleFailedPayment();
      return false;
    }
  };

  const saveUserInfo = (user = {}, appointmentId) => {
    const updatedUser = {
      ...userState,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    };
    if (user.cad && appointmentId)
      updatedUser.notifications = { isCNDDocsSubmitted: appointmentId };
    dispatch(saveUser(updatedUser));
  };

  const goToOrderSummary = () => {
    module === "appointment"
      ? toUrlWithData(localeRoutes.appointment[lang], config.appointmentUrlState)
      : module === "upsell"
        ? toTrackYourProgress()
        : module === "eshop"
          ? toShopCheckout()
          : module === "subscription"
            ? toSubscribeOffer(draftVals?.cart?.offers?.[0]?.slugUrl)
            : null;
  };

  // const myCss = `
  // .wpwl-button-pay {
  //   background-color: ${termsAccepted ? "#267e97 !important" : "#267e979e"};
  // }`;

  return (
    <>
      <Loader show={loader || isPaying} />

      <Modal show={show} onHide={hide} fullscreen={true} centered>
        <Modal.Body className="p-0">
          {module === "eshop" && <Breadcrumb onPayment={true} paymentDisplay={hide} />}

          <div className="checkout card-details">
            <div className="pay-form">
              <div className="logo text-center">
                <img src={logoSet[lang]} alt="autohub-logo" />
              </div>

              {!isPaying && config.card !== "TAMARA" ? (
                <>
                  <div className="pay-head">
                    <span onClick={hide}>
                      {langConfig.backIcon[lang]}
                      {t("addons.goBackBtn")}
                    </span>
                    <h6>{t("addons.enterCardDetails")}</h6>
                  </div>

                  <div className="hyperpay-form">
                    <div id="portal-root">
                      <form
                        action={window.location.href}
                        className="paymentWidgets"
                        data-brands={config.card}></form>
                    </div>
                    {/* <style>{myCss}</style> */}
                  </div>
                </>
              ) : (
                <h3 className="mt-5 text-center">{t("addons.orderProcessingText")}</h3>
              )}
            </div>
          </div>

          <Helmet>
            <script type="text/javascript">{scriptOptions(lang)}</script>
          </Helmet>
        </Modal.Body>
      </Modal>
    </>
  );
};

const scriptOptions = (lang) => {
  console.log(">>> locale", lang);
  return `
var wpwlOptions = {
  style: "plain",
  locale: "${lang}",
  iframeStyles: {
    'card-number-placeholder': {
      'color': ' #888',
      'font-size': '16px',
      'font-family': 'MarkPro'
    },
    'cvv-placeholder': {
      'color': ' #888',
      'font-size': '16px',
      'font-family': 'MarkPro'
    }

  },
  showCVVHint: true,
  brandDetection: true,
  onReady: function () {
    $(".wpwl-group-cardNumber").after($(".wpwl-group-brand").detach());
    $(".wpwl-group-cvv").after($(".wpwl-group-cardHolder").detach());
    var visa = $(".wpwl-brand:first").clone().removeAttr("class").attr("class", "wpwl-brand-card wpwl-brand-custom wpwl-brand-VISA")
    var master = $(visa).clone().removeClass("wpwl-brand-VISA").addClass("wpwl-brand-MASTER");
    $(".wpwl-brand:first").after($(master)).after($(visa));
    $('form.wpwl-form-card').find('.wpwl-button').on('click', function (e) {
      validateHolder(e);
    });
  },
  billingAddress: {
    country: "SA",
    city: "Jeddah",
    state: "Western",
    postcode: "123",
    street1: "ABC",
    street2: ""
  },
  mandatoryBillingFields: {
    country: true,
    state: true,
    city: true,
    postcode: true,
    street1: true,
  },
}

function validateHolder(e) {
  var holder = $('.wpwl-control-cardHolder').val();
  if (holder.trim().length < 2) {
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
    return false;
  }
  return true;
}
`;
};

export default React.memo(PaymentProcessor);
