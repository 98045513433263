import axios from "axios";
import {
  API_GET_SUBSCRIPTION,
  API_SUBSCRIBE_OFFER,
  API_GET_SUBSCRIBED_OFFERS,
  API_GET_SUBSCRIBED_OFFER,
  API_GET_USER_SUBSCRIBED_OFFER,
  API_GET_ALL_SUBSCRIPTIONS,
  API_GET_SUBSCRIPTION_DETAILS
} from "../config/webService";
import BaseService from "../config/baseService";
import { createCarNickName, currentLanguage } from "../util/common";

class SubscriptionService extends BaseService {
  async getSubscription(slug) {
    const result = await axios.get(API_GET_SUBSCRIPTION, { params: { slugUrl: slug } });
    return result?.data?.response;
  }

  async subscribeOffer(values) {
    const payload = {
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.mobilePhone,
      email: values.email,
      make: values.Brand,
      model: values.Model,
      year: values.Year,
      otherBrand: values.OtherBrand,
      otherModel: values.OtherModel,
      licencePlate: values.plateNumber + values.plateChar,
      carNickName: createCarNickName({
        username: values.firstName,
        carNickName: values.carNickName,
        model: values.Model,
        otherModel: values.OtherModel
      }),
      branchId: values.branchId,
      subscriptionId: values.cart?.offers?.[0]?._id,
      quota: {
        total: values.cart?.offers?.[0]?.quota,
        consume: 0,
        left: values.cart?.offers?.[0]?.quota
      },
      language: currentLanguage()
    };

    const result = await axios.post(API_SUBSCRIBE_OFFER, payload);
    return result?.data?.response;
  }

  async getSubscribedOffers() {
    const result = await axios.get(API_GET_SUBSCRIBED_OFFERS);
    return result?.data?.response;
  }

  async getSubscribedOfferById(id) {
    const result = await axios.get(API_GET_SUBSCRIBED_OFFER + id);
    return result?.data?.response;
  }

  async getUserSubscribedOffer(id) {
    const result = await axios.get(API_GET_USER_SUBSCRIBED_OFFER + id);
    return result?.data?.response;
  }

  async getSubscriptionList() {
    const result = await axios.get(API_GET_ALL_SUBSCRIPTIONS);
    return result?.data?.response;
  }

  async getSubscriptionDetails(slug) {
    const result = await axios.get(API_GET_SUBSCRIPTION_DETAILS, { params: { url: slug } });
    return result?.data?.response;
  }
}

export default new SubscriptionService();
