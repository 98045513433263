import React from "react";
import loaderLogoEn from "../../images/loader-logo-en.gif";
import loaderLogoAr from "../../images/loader-logo-ar.gif";
import { currentLanguage } from "../../util/common";

const Loader = ({ show }) => {
  const lang = currentLanguage();
  const logo = {
    en: loaderLogoEn,
    ar: loaderLogoAr
  };

  return (
    show && (
      <div className="loader">
        <img src={logo[lang]} />
      </div>
    )
  );
};

export default React.memo(Loader);
