export const getImageKey = (e) => {
  let key;
  if (e.id === "leftSide") key = "LeftImage";
  else if (e.id === "rightSide") key = "RightImage";
  else if (e.id === "frontSide") key = "FrontImage";
  else if (e.id === "rearSide") key = "RearImage";
  return key;
};

export const changePointer = (key) => {
  if (key === "all") {
    const pointers = document.querySelectorAll(".hotspot_indicator");
    pointers.forEach((e) => {
      e.style.backgroundImage = `url('${window.location.origin}/graphics/circle-check.svg')`;
    });

    return;
  }

  const updatedKey = key.toLowerCase() + "Side_indicator";
  document.getElementsByClassName(
    updatedKey
  )[0].style.backgroundImage = `url('${window.location.origin}/graphics/circle-check.svg')`;
};
