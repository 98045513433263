import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../shared";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppNavigation } from "../../../hooks";
import "./index.scss";

const CNDBookingDocsAlert = () => {
  const { t } = useTranslation();
  const { localeRoutes, toFastDrop } = useAppNavigation();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user.details);
  const [showAlert, setShowAlert] = useState(false);

  const appointmentId = useMemo(() => user.notifications?.isCNDDocsSubmitted, [user]);

  useEffect(() => {
    if (appointmentId && !pathname.includes(localeRoutes.fastDrop.ar)) setShowAlert(true);
    else handleCloseAlert();
  }, [pathname, appointmentId]);

  const handleCloseAlert = () => setShowAlert(false);
  const goToFastDrop = () => toFastDrop(appointmentId);

  return (
    <>
      {showAlert && (
        <div className="cnd-docs-alert">
          <h5>{t("scheduleAppointment.cndDocAlertTitle")}</h5>
          <p>{t("scheduleAppointment.cndDocAlertText")} </p>
          <div className="btn-wrap">
            <Button type="small-roundedLeft" customize="secondary" action={handleCloseAlert}>
              {t("scheduleAppointment.cndCloseAlertBtn")}
            </Button>
            <Button action={goToFastDrop}>{t("scheduleAppointment.cndDocAlertBtn")}</Button>
          </div>
        </div>
      )}
    </>
  );
};
export default React.memo(CNDBookingDocsAlert);
