import axios from "axios";
import { API_GET_BANNERS, API_GET_HEADLINE } from "../config/webService";
import BaseService from "../config/baseService";

class HomeService extends BaseService {
  async getBanners() {
    const response = await axios.get(API_GET_BANNERS);
    return response && response.data?.response;
  }

  async getHeadlines() {
    const response = await axios.get(API_GET_HEADLINE);
    return response && response.data?.response;
  }
}

export default new HomeService();
