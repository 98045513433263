import React from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";

const LightBox = (props) => {
  const { data, onClose } = props;

  return (
    <>
      {data && (
        <div className="lightbox">
          <ReactImageVideoLightbox
            data={data.media}
            startIndex={data.start}
            showResourceCount={true}
            onCloseCallback={onClose}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(LightBox);
