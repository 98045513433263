import i18n from "i18next";
// import Backend from "i18next-xhr-backend";
// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "../src/localization/en/translation.json";
import translationAR from "../src/localization/ar/translation.json";

import Service from "./services/translation";
import { merge } from "lodash";
import { currentLanguage } from "./util/common";

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

const loadResources = async () => {
  try {
    const translation = await Service.getTranslations();
    const en = merge(translationEN, translation.value_en);
    const ar = merge(translationAR, translation.value_ar);
    i18n.addResourceBundle("en", "translation", en);
    i18n.addResourceBundle("ar", "translation", ar);
    i18n.changeLanguage(currentLanguage());
  } catch (error) {
    console.error("Translation resources error: ", error);
  }
};

loadResources();
// const backendOptions = {
//     loadPath: '{{lng}}|{{ns}}',
//     request: (options, url, payload, callback) => {
//         debugger
//       try {
//         const [lng] = url.split('|');
//         loadResources().then((response) => {
//           callback(null, {
//             data: response,
//             status: 200,
//           });
//         });
//       } catch (e) {
//         console.error(e);
//         callback(null, {
//           status: 500,
//         });
//       }
//     },
//   };

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    // backend: backendOptions,
    fallbackLng: "ar",
    debug: true,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
