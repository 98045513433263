import { toast } from "react-toastify";

const toastConfig = (options) => {
  return {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    ...options
  };
};

class NotificationHelper {
  success = (msg = "Success", options) => toast.success(msg, toastConfig(options));

  error = (msg = "Error", options) => toast.error(msg, toastConfig(options));

  info = (msg = "Info", options) => toast.info(msg, toastConfig(options));
}

export default new NotificationHelper();
