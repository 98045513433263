import { UserService } from "../services";

// My account: Getting car brands
export const getCarBrands = async (setBrandOptions, serviceId) => {
  try {
    const brands = await UserService.getBrands({ serviceId });
    if (brands) setBrandOptions(brands);
  } catch (e) {
    console.error(e);
  }
};

// My account: Getting car models
export const getCarModels = async (brand, setModelOptions) => {
  try {
    const models = await UserService.getModels({ brand });
    if (models) {
      setModelOptions(models);
      return models;
    }
  } catch (e) {
    console.error(e);
  }
};

// My account: Getting car model years
export const getCarYears = async (brand, model, setYearOptions) => {
  try {
    const years = await UserService.getYears({ brand, model });
    if (years) setYearOptions(years);
  } catch (e) {
    console.error(e);
  }
};

// My account: Field value setter to update any car
export const fieldValueSetter = (vehiclesFormRef, car, language) => {
  vehiclesFormRef.current.setFieldValue("Brand", car.brand?.["value_" + language]);
  vehiclesFormRef.current.setFieldValue("OtherBrand", car.otherBrand || "");
  vehiclesFormRef.current.setFieldValue("Model", car.product?.["value_" + language]);
  vehiclesFormRef.current.setFieldValue("OtherModel", car.otherModel || "");
  vehiclesFormRef.current.setFieldValue("Year", car.modelYear);
  vehiclesFormRef.current.setFieldValue("plateNumber", splitCarNumberPlate(car.carNumberPlate)[0]);
  vehiclesFormRef.current.setFieldValue("plateChar", splitCarNumberPlate(car.carNumberPlate)[1]);
  vehiclesFormRef.current.setFieldValue("carNickName", car.carNickName);
};

// Global: format car plate number as needed
export const getFormattedCarNumberPlate = (num) =>
  num?.slice(0, 4) + "-" + num?.slice(4).toUpperCase();

// Global: split car plate number into numbers and characters
export const splitCarNumberPlate = (num = "") => [
  num.split("-")[0] || "",
  num.split("-")[1]?.toUpperCase() || ""
];
