import React from "react";

const FormTogglers = ({ dependentValue, dirty, tabHandler, tab = 2, t, customize }) =>
  !dependentValue || dirty ? (
    <input
      type="submit"
      value={t("loginModal.nextBtnText")}
      className={customize && "ah-btn btn primary-btn mx-0"}
    />
  ) : (
    <button
      type="submit"
      className={customize && "ah-btn btn primary-btn mx-0"}
      onClick={(e) => {
        e.preventDefault();
        tabHandler(tab);
      }}>
      {t("loginModal.nextBtnText")}
    </button>
  );

export default React.memo(FormTogglers);
