import { createSlice } from "@reduxjs/toolkit";

const contentSlice = createSlice({
  name: "content",
  initialState: {
    pages: null,
    media: {}
  },
  reducers: {
    savePages: (state, action) => {
      state.pages = action.payload;
    },
    saveMedia: (state, action) => {
      state.media = action.payload;
    }
  }
});

export const { savePages, saveMedia } = contentSlice.actions;
export default contentSlice.reducer;
