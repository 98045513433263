import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Offcanvas } from "react-bootstrap";
import { GET_MEDIA_URL } from "../../config/webService";
import { Button, Icon } from "./";
import { currentLanguage } from "../../util/common";
import { useDispatch, useSelector } from "react-redux";
import { removeAllItems, removeItem } from "../../redux/reducers/cartReducer";
import { langConfig } from "../../util/addonsUtils";
import { editEshopCart } from "../../util/eshopUtils";
import { useAppNavigation } from "../../hooks";

const EshopCart = ({ branchId }) => {
  const { t } = useTranslation();
  const { localeRoutes, toUrlWithData } = useAppNavigation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const subTotal = useSelector((state) => state.cart.total);

  const [cartShow, setCartShow] = useState(false);

  const lang = currentLanguage();

  const emptyCart = () => {
    dispatch(removeAllItems());
    handleCartShow();
  };
  const handleCartShow = () => setCartShow(!cartShow);
  const handleRemoveItem = (item) => dispatch(removeItem(item));
  const goToCheckout = () => toUrlWithData(localeRoutes.shopCheckout[lang], { branchId: branchId });

  return (
    <>
      <div className="user-cart" onClick={handleCartShow}>
        <Icon iconName="cart" />
        {/* <span>{cartQuantity || 0}</span> */}
        <span>{cartItems?.length || 0}</span>
      </div>

      <Offcanvas show={cartShow} onHide={handleCartShow} placement={langConfig.drawerPlace[lang]}>
        <Offcanvas.Header>
          <h6>{t("shop.shoppingCart")}</h6>
          <Icon iconName="xmark" onClick={handleCartShow} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cartItems.length ? (
            <>
              {cartItems.map((item, key) => {
                return (
                  <div className="product" key={key}>
                    <div>
                      <p>{item.name?.["value_" + lang]}</p>
                      <div className="values">
                        <span className="price">
                          {item.price} {t("addons.currency")}
                        </span>
                        <span className="item-quantity">
                          <Icon iconName="xmark" />
                        </span>
                        <span className="counter">
                          <span
                            className={`${item?.quantity > 1}`}
                            onClick={() =>
                              editEshopCart({ type: "-", item, cartItems, dispatch, t })
                            }>
                            <Icon iconName="minus" />
                          </span>
                          {item?.quantity}
                          <span
                            className={`${item?.quantity < item?.totalRemainingQuantity}`}
                            onClick={() =>
                              editEshopCart({ type: "+", item, cartItems, dispatch, t })
                            }>
                            <Icon iconName="plus" />
                          </span>
                        </span>
                      </div>
                      <span className="remove" onClick={() => handleRemoveItem(item)}>
                        <Icon iconName="trash" /> {t("shop.removeBtn")}
                      </span>
                      <div className="cart-subtotal">
                        {/* <label>{t("shop.totalAmount")}</label> */}
                        <span>
                          {Number(item?.quantity) * Number(item.price)} {t("addons.currency")}
                        </span>
                      </div>
                    </div>
                    <div>
                      <img src={GET_MEDIA_URL + item.image} />
                    </div>
                  </div>
                );
              })}

              <div className="cart-subtotal">
                <label>{t("shop.subtotal")}</label>
                <span>
                  {subTotal} {t("addons.currency")}
                </span>
              </div>
              <div className="controls">
                <Button type="small-roundedLeft" action={emptyCart}>
                  {t("shop.emptyCartBtn")}
                </Button>
                <Button type="blue-roundedRight" disabled={!cartItems.length} action={goToCheckout}>
                  {t("shop.checkoutBtn")}
                </Button>
              </div>
              <div className="collect-notes margin">
                <h5>{t("shop.collectionNote")}</h5>
                <div className="address-details">
                  {lang === "en" ? (
                    <>
                      <p>
                        <span>Branch Address: </span>
                        7949 2313 Prince Majid Rd, Al-Safa, Jeddah 23452, Saudi Arabia
                      </p>
                      <a href="https://tiny.one/Jed-Safa" target="_blank" rel="noreferrer">
                        View on map
                        <Icon iconName="map" />
                      </a>
                    </>
                  ) : (
                    <>
                      <p>
                        <span>عنوان فرع: </span>
                        طريق الأمير ماجد - حي الصفا
                      </p>
                      <a href="https://tiny.one/Jed-Safa" target="_blank" rel="noreferrer">
                        عرض على الخريطة
                        <Icon iconName="map" />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <h6>{t("shop.nothingInCart")}</h6>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default React.memo(EshopCart);
