import axios from "axios";
import BaseService from "../config/baseService";
import { API_GET_CATEGORIES, API_GET_VARIANTS } from "../config/webService";

class ProductService extends BaseService {
  constructor() {
    super();
  }

  async getCategories(branchId) {
    const response = await axios.get(`${API_GET_CATEGORIES}?branchId=${branchId}`);
    return response && response.data;
  }

  async getVariants(branchId, categoryIds, variantId, categoryId) {
    const response = await axios.get(`${API_GET_VARIANTS}`, {
      params: {
        branchId,
        categoryIds,
        variantId,
        categoryId
      }
    });
    return response && response.data;
  }
}

export default new ProductService();
