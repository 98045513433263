import React from "react";
import { currentLanguage } from "../../util/common";
import { Icon } from "./";
import { useTranslation } from "react-i18next";

const BranchDetails = ({ details, mainClass = "", parentClass = "" }) => {
  const lang = currentLanguage();
  const { t } = useTranslation();

  return (
    <>
      {details && (
        <div className={mainClass}>
          <div className={"address-details " + parentClass}>
            <p>
              <span>{t("scheduleAppointment.branchAddress")} </span>
              {lang === "en" ? details.address : details.addressArabic}
            </p>

            <a
              href={
                details?.addressLink
                  ? details.addressLink
                  : `http://maps.google.com/maps?q=${details.location?.coordinates[1]},${details.location?.coordinates[0]}`
              }
              target="_blank"
              rel="noreferrer">
              {t("scheduleAppointment.viewOnMap")}
              <Icon iconName="map" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BranchDetails);
