import { createSlice } from "@reduxjs/toolkit";
import { dispatchStorage } from "../../util/common";

const userSlice = createSlice({
  name: "user",
  initialState: {
    details: {}
  },
  reducers: {
    saveUser: (state, action) => {
      state.details = action.payload;
    },
    logoutUser: (state) => {
      state.details = {};
      // deleteStorage("otp");
      dispatchStorage("token", "TEMP_TOKEN");
    }
  }
});

export const { saveUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
