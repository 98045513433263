import React from "react";
import card from "../../images/card.svg";
import car from "../../images/car.svg";
import carBlue from "../../images/car-blue.svg";
import damageCar from "../../images/damage-car.svg";
import doc from "../../images/document-icon.svg";
import damageCarBlue from "../../images/damage-car-blue.svg";
import docBlue from "../../images/document-icon-blue.svg";
import { Stepper, Step } from "react-form-stepper";
import { useTranslation } from "react-i18next";
import { Icon } from "./";

const StatusBar = ({ className = "", activeStep = -1, type = "estimation" }) => {
  const { t } = useTranslation();
  const stepperClasses = "steppers p-0 " + className;
  const activeScreen =
    type === "estimation" ? (activeStep === 2 ? 1 : activeStep === 3 ? 2 : activeStep) : activeStep;

  return (
    <div className="container">
      <div className="col-md-9 col-lg-7 m-auto styled-stepper">
        <Stepper activeStep={activeScreen} className={stepperClasses} hideConnectors="inactive">
          <Step label={t("estimationRequest.personalDetails")}>
            <img src={card} />
          </Step>
          <Step label={t("estimationRequest.vehicleDetails")}>
            <img src={activeStep >= 1 ? carBlue : car} />
          </Step>
          {type === "estimation" && (
            <Step label={t("estimationRequest.damageDetails")}>
              <img src={activeStep >= 3 ? damageCarBlue : damageCar} />
            </Step>
          )}
          {type === "estimation" && (
            <Step label={t("estimationRequest.reviewTandC")}>
              <img src={activeStep >= 4 ? docBlue : doc} />
            </Step>
          )}
          {type === "quote" && (
            <Step label={t("quotations.quoteDetails")}>
              <Icon
                iconName="magnifyingDollar"
                className={`quote-step-icon ${activeStep >= 2 ? "selected" : ""}`}
              />
            </Step>
          )}
          {type === "subscription" && (
            <Step label={t("subscription.checkout")}>
              <Icon
                iconName="creditCard"
                className={`quote-step-icon ${activeStep >= 2 ? "selected" : ""}`}
              />
            </Step>
          )}
        </Stepper>
      </div>
    </div>
  );
};

export default StatusBar;
