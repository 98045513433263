import React from "react";
import { Helmet } from "react-helmet";
import { isLtr } from "../../util/common";
import { useTranslation } from "react-i18next";

const AppHelmet = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="H4RDJsA"
        />
        <title>{isLtr() ? t("home.autohub") : t("home.autohub")}</title>

        {/* First Script Tag */}
        <script
          type="text/javascript"
          src="https://cdn-app5.securiti.ai/consent/auto_blocking/82a21883-dcae-4428-bebb-18ce3f14b54c/98fd696a-1594-4459-9b54-1d4eef238be6.js"
        ></script>
        
        {/* Second Script Tag */}
        <script type="text/javascript">
          {`
            (function () {
              var s = document.createElement('script');
              s.src = 'https://cdn-app5.securiti.ai/consent/cookie-consent-sdk-loader.js';
              s.setAttribute('data-tenant-uuid', '82a21883-dcae-4428-bebb-18ce3f14b54c');
              s.setAttribute('data-domain-uuid', '98fd696a-1594-4459-9b54-1d4eef238be6');
              s.setAttribute('data-backend-url', 'https://app5.securiti.ai');
              s.setAttribute('data-skip-css', 'false');
              s.defer = true;
              s.addEventListener('load', function() {
                let url = window?.location?.href;
                let lang;

                if (url.includes('https://autohubksa.com/en/')) {
                  lang = 'en';
                } else if (url.includes('https://autohubksa.com/')) {
                  lang = 'ar';
                }

                if (lang === "ar") {
                  window.setConsentBannerParams({
                    overrideBannerLanguage: {
                      'https://autohubksa.com/': "ar",
                    },
                  });
                  console.log(
                    "Banner language parameters set for Arabic."
                  );
                } else if (lang === "en") {
                  window.setConsentBannerParams({
                    overrideBannerLanguage: {
                      'https://autohubksa.com/en/': "en",
                    },
                  });
                  console.log(
                    "Banner language parameters set for English."
                  );
                }
              });
              var parent_node = document.head || document.body;
              parent_node.appendChild(s);
            })();
          `}
        </script>
      </Helmet>
    </>
  );
};

export default AppHelmet;
