import { useState, useEffect, useCallback } from "react";
import CampaignService from "../services/campaign";
import SubscriptionService from "../services/subscriptionService";

const useOffer = () => {
  const [loading, setLoading] = useState(false);
  const [specialOffers, setSpecialOffers] = useState([]);
  const [everydayDeals, setEverydayDeals] = useState([]);
  const [prePaidServices, setPrePaidServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [serviceCategories, setServiceCategories] = useState([]);
  const [mostPopularServices, setMostPopularServices] = useState([]);

  const [paginationData, setPaginationData] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
    fetchCampaigns({});
    fetchSubscriptions();
    return () => {
      setSelectedCategory("");
    };
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await CampaignService.getServiceCategorizes();
      setServiceCategories(response);
      setPaginationData(initializePagination(response));
    } catch (err) {
      console.log(err);
    }
  };

  const initializePagination = (categories) => {
    const defaultCategories = [
      { name: { value_en: "All Services", value_ar: "جميع الخدمات" }, id: "", pageIndex: 1 },
      {
        name: { value_en: "Most Popular", value_ar: "الأكثر شعبية" },
        id: "0",
        pageIndex: 1,
        offerType: "prePaid"
      }
    ];

    return [
      ...defaultCategories,
      ...categories.map((category) => ({
        name: category.name,
        id: category._id,
        pageIndex: 1
      }))
    ];
  };

  const fetchCampaigns = async ({
    categoryName = "",
    offerType,
    prePaid = prePaidServices,
    special = specialOffers,
    deals = everydayDeals,
    popular = mostPopularServices,
    categoryId = "",
    pageSize = 4
  }) => {
    setLoading(true);
    try {
      const campaigns = await CampaignService.getCategorizedCampaign({
        pageIndex: categoryName ? getCurrentPageIndex(categoryName).pageIndex + 1 : 1,
        pageSize,
        categoryId
      });

      if (campaigns) {
        categorizeOffers({ campaigns, categoryName, offerType, prePaid, special, deals, popular });
      }
      if (categoryName) {
        setPaginationData(incrementPageIndex(paginationData, categoryName));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const subscriptionsResponse = await SubscriptionService.getSubscriptionList();
      setSubscriptions(subscriptionsResponse);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const categorizeOffers = useCallback(
    ({ campaigns, categoryName, offerType, prePaid, popular, special, deals }) => {
      const filteredSpecialOffers = campaigns?.regularServices?.filter(
        (offer) => offer.offerType === "specialOffer"
      );
      const filteredDeals = campaigns?.regularServices?.filter(
        (offer) => offer.offerType === "everydayDeal"
      );
      const filteredPrePaid = campaigns?.regularServices?.filter(
        (offer) => offer.offerType === "prePaid"
      );
      const filteredPopular = campaigns?.popularServices?.filter(
        (offer) => offer.offerType === "prePaid"
      );

      if (categoryName?.length) {
        if (categoryName === "Most Popular") {
          popular[0].services.push(...filteredPopular[0].services);
          popular[0].remainingServices = filteredPopular[0].remainingServices;
          setMostPopularServices([...popular]);
        } else {
          if (offerType === "prePaid") {
            updateServices(prePaid, filteredPrePaid, categoryName, setPrePaidServices);
          } else if (offerType === "everydayDeal") {
            updateServices(deals, filteredDeals, categoryName, setEverydayDeals);
          } else {
            updateServices(special, filteredSpecialOffers, categoryName, setSpecialOffers);
          }

          // const parentIndex = prePaid.findIndex(
          //   (obj) => obj?.categoryName?.value_en === categoryName
          // );
          // const childIndex = filteredPrePaid.findIndex(
          //   (obj) => obj?.categoryName?.value_en === categoryName
          // );

          // if (parentIndex !== -1 && childIndex !== -1) {
          //   prePaid[parentIndex].services.push(...filteredPrePaid[childIndex].services);
          //   prePaid[parentIndex].remainingServices = filteredPrePaid[childIndex].remainingServices;
          //   setPrePaidServices([...prePaid]);
          // }
        }
      } else {
        setSpecialOffers(filteredSpecialOffers || []);
        setEverydayDeals(filteredDeals || []);
        setPrePaidServices(filteredPrePaid || []);
        setMostPopularServices(filteredPopular || []);
      }
    },
    []
  );

  const updateServices = (mainList, filteredList, categoryName, setter) => {
    const parentIndex = mainList.findIndex((obj) => obj?.categoryName?.value_en === categoryName);
    const childIndex = filteredList.findIndex(
      (obj) => obj?.categoryName?.value_en === categoryName
    );

    if (parentIndex !== -1 && childIndex !== -1) {
      mainList[parentIndex].services.push(...filteredList[childIndex].services);
      mainList[parentIndex].remainingServices = filteredList[childIndex].remainingServices;
      setter([...mainList]);
    }
  };

  const loadMoreOffers = useCallback(
    ({ name, offerType, prePaid, special, deals, selectedCategory, popular }) => {
      fetchCampaigns({
        categoryName: name,
        offerType,
        prePaid,
        special,
        deals,
        categoryId: selectedCategory,
        pageSize: selectedCategory?.length ? 10 : 4,
        popular
      });
    },
    [paginationData]
  );

  const getCurrentPageIndex = (name) => {
    return paginationData.find((obj) => obj.name.value_en === name);
  };

  const incrementPageIndex = (data, name = "") => {
    return data.map((obj) => {
      if (obj.name.value_en === name) {
        return { ...obj, pageIndex: obj.pageIndex + 1 };
      }
      return obj;
    });
  };

  const selectCategory = (id) => {
    if (selectedCategory === id) return;
    setSelectedCategory(id);
    fetchCampaigns({ categoryId: id, pageSize: id?.length ? 10 : 4 });
    setPaginationData(initializePagination(serviceCategories));
  };

  return {
    loading,
    specialOffers,
    everydayDeals,
    subscriptions,
    prePaidServices,
    loadMoreOffers,
    paginationData,
    selectCategory,
    selectedCategory,
    mostPopularServices
  };
};

export default useOffer;
