import React from "react";
import {
  SMALL_ROUNDED_RIGHT,
  SMALL_ROUNDED_LEFT,
  BLUE_ROUNDED_LEFT,
  BIG_ROUNDED_RIGHT,
  BIG_ROUNDED_LEFT,
  BG_WHITE,
  BLUE_ROUNDED_RIGHT
} from "../../util/constants";

const getClasses = {
  [SMALL_ROUNDED_RIGHT]: "rounded-right",
  [SMALL_ROUNDED_LEFT]: "rounded-left",
  [BLUE_ROUNDED_LEFT]: "primary-btn-roundLeft",
  [BLUE_ROUNDED_RIGHT]: "primary-btn-blueRoundRight",
  [BIG_ROUNDED_RIGHT]: "primary-btn-big",
  [BIG_ROUNDED_LEFT]: "primary-btn-big rounded-left",
  [BG_WHITE]: "white"
};

const Button = ({ type, btnType = "button", action, customize, disabled = "", ...rest }) => {
  return (
    <button
      type={btnType}
      className={`${type !== "simple" ? "ah-btn btn primary-btn" : ""} ${getClasses[type] || ""} ${
        customize ? customize : ""
      } ${disabled && "disabled"}`}
      onClick={action}
      disabled={disabled}
      {...rest}>
      {rest.children}
    </button>
  );
};

export default React.memo(Button);
