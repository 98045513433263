import axios from "axios";
import BaseService from "../config/baseService";
import {
  API_ADDON_OFFERS,
  API_ADDON_VARIANTS,
  API_ADDON_ORDER,
  API_POST_PROMO,
  API_GET_ORDERS,
  API_GET_ORDER_BY_ID
} from "../config/webService";

class AddonsService extends BaseService {
  async getAddonOffers(serviceId, branchId) {
    const result = await axios.get(API_ADDON_OFFERS, {
      params: {
        serviceId,
        branchId
      }
    });

    return result && result.data?.response;
  }

  async getUpellAddonOffers(serviceCode, branchCode) {
    const result = await axios.get(API_ADDON_OFFERS, {
      params: {
        serviceCode,
        branchCode
      }
    });
    return result && result.data?.response;
  }

  async getAddonVariants(serviceId, brandId) {
    const response = await axios.get(
      `${API_ADDON_VARIANTS}?serviceId=${serviceId}&branchId=${brandId}`
    );
    return response && response.data;
  }

  async getUpellAddonVariants(serviceCode, branchCode) {
    const response = await axios.get(API_ADDON_VARIANTS, {
      params: {
        serviceCode,
        branchCode
      }
    });
    return response && response.data;
  }

  async createAddonOrder(orderPayload) {
    const response = await axios.post(API_ADDON_ORDER, orderPayload);
    return response && response.data;
  }

  async applyPromo(payload) {
    const response = await axios.get(API_POST_PROMO, {
      params: payload
    });
    return response && response.data;
  }

  async getOrders(userId) {
    const result = await axios.get(API_GET_ORDERS, {
      params: {
        userId
      }
    });

    return result?.data?.response;
  }

  async getOrderById(id) {
    const result = await axios.get(API_GET_ORDER_BY_ID + "/" + id);
    return result?.data?.response;
  }
}

export default new AddonsService();
