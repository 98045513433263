import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    total: 0
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
      state.total = calculatedTotal(state.items);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item._id !== action.payload._id);
      state.total = calculatedTotal(state.items);
    },
    updateItem: (state, action) => {
      const { _id, updatedItem } = action.payload;
      const itemIndex = state.items.findIndex((item) => item._id === _id);
      if (itemIndex !== -1) {
        state.items[itemIndex] = updatedItem;
      }
      state.total = calculatedTotal(state.items);
    },
    removeAllItems: (state) => {
      state.items = [];
      state.total = 0;
    }
  }
});

const calculatedTotal = (items) =>
  items.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

export const { addItem, removeItem, updateItem, removeAllItems } = cartSlice.actions;
export default cartSlice.reducer;
