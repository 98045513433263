import axios from "axios";
import { API_GET_AUTOHUB_LOCALIZATIONS } from "../config/webService";
import BaseService from "../config/baseService";

class TranslationService extends BaseService {
  async getTranslations() {
    const result = await axios.get(API_GET_AUTOHUB_LOCALIZATIONS);
    return result?.data?.response;
  }
}

export default new TranslationService();
