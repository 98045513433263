import React from "react";

const MessageBox = ({ type, ...rest }) =>
  rest.children && (
    <div className={`message-box ${type}`}>
      <span>{rest.children}</span>
    </div>
  );

export default React.memo(MessageBox);
