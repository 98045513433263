import axios from "axios";
import { API_GET_MEDIA, API_GET_PUBLISHED_ROUTES } from "../config/webService";
import BaseService from "../config/baseService";

class PagesService extends BaseService {
  async getPages() {
    const result = await axios.get(API_GET_PUBLISHED_ROUTES);
    const response = result?.data?.response?.map((item) => item.name);
    return response;
  }

  async getMedia() {
    const response = await axios.get(API_GET_MEDIA);
    return response && response.data;
  }
}

export default new PagesService();
