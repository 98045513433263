import * as Yup from "yup";
import { emailFormatValidator } from "./common";

export const authValidations = (params = {}) => {
  const { validateDetails = false, emailRequired = true, phoneNumberLength = 9 } = params;

  let validations = {
    phoneCode: Yup.string().required("myAccount.required"),
    phoneNumber: Yup.string()
      .min(phoneNumberLength, "myAccount.tooShort")
      .max(phoneNumberLength, "myAccount.tooLong")
      .required("myAccount.required")
  };

  if (phoneNumberLength === 13) delete validations.phoneCode;

  if (validateDetails) {
    const userDetailsValidations = {
      title: Yup.string().trim().required("myAccount.required"),
      firstName: Yup.string().trim().required("myAccount.required"),
      lastName: Yup.string().trim().required("myAccount.required"),
      email: Yup.string()
        .trim()
        .required("myAccount.required")
        .test("is-valid-email", "myAccount.validEmail", emailFormatValidator)
    };

    if (!emailRequired)
      userDetailsValidations.email = Yup.string()
        .trim()
        .test("is-valid-email", "myAccount.validEmail", emailFormatValidator);
    validations = { ...validations, ...userDetailsValidations };
  }

  const schema = Yup.object().shape(validations);
  return schema;
};

export const authOTPValidation = () => {
  return Yup.object().shape({
    code1: Yup.string().required(),
    code2: Yup.string().required(),
    code3: Yup.string().required(),
    code4: Yup.string().required()
  });
};
