import { NotificationHelper } from "../components/shared";
import { addItem, updateItem } from "../redux/reducers/cartReducer";

export const handleAddItem = (
  product = {},
  productCount,
  cartItems,
  dispatch,
  t,
  user,
  openLoginModal
) => {
  if (user && !Object.keys(user).length) {
    openLoginModal();
    return;
  }

  if (productCount < 1 || productCount > product.variant?.totalRemainingQuantity) return;

  const newItem = {
    ...product.variant,
    quantity: productCount,
    price: product.price,
    totalRemainingQuantity: product.totalRemainingQuantity,
    image: product.variant?.image,
    type: "products"
  };
  const productIndex = cartItems.findIndex((item) => item._id === product.variant?._id);
  if (productIndex !== -1) handleUpdateItem(cartItems[productIndex], productCount, dispatch);
  else dispatch(addItem(newItem));

  if (user)
    NotificationHelper.success(product.variant?.name?.value_en + t("addons.addItemMsg"), {
      theme: "dark"
    });
};

export const handleUpdateItem = (item, productCount, dispatch) => {
  const updatedItem = { ...item, quantity: productCount };
  dispatch(updateItem({ _id: item._id, updatedItem }));
};

export const editEshopCart = ({ type, item, cartItems, dispatch, t }) => {
  if (type === "+" && item?.quantity + 1 >= item?.totalRemainingQuantity) {
    NotificationHelper.error(item?.name?.value_en + t("addons.quantityExceeded"), {
      theme: "colored"
    });
  }

  handleAddItem(
    { variant: item },
    type === "+" ? item.quantity + 1 : item.quantity - 1,
    cartItems,
    dispatch,
    t
  );
};
