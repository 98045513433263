import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import checkedMark from "../../images/checked.svg";
import { useLocation } from "react-router-dom";
import { currentLanguage } from "../../util/common";
import { Button } from "./";
import { useAppNavigation } from "../../hooks";

const SuccessScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { localeRoutes, toNotFound, toOffer, toUrlWithData } = useAppNavigation();

  const module = location.state?.from;

  useEffect(() => {
    if (!module) return toNotFound();
  }, []);

  return (
    <div className="position-relative">
      <div className="container success-screen design-props py-5 px-4">
        {module === "appointment" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("addons.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("addons.userPurpose")}</h4>
            <p className="no-general">{t("addons.successPara1")}</p>
            <p className="no-general">{t("addons.successPara2")}</p>
            <p className="no-general">{t("addons.successPara3")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight m-0" customize="mx-0" action={toOffer}>
                {t("addons.reviewOrder")}
              </Button>
            </div>
          </div>
        ) : module === "estimation" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("estimationRequest.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("estimationRequest.successSubHeading")}</h4>
            <p>{t("estimationRequest.successPara1")}</p>
            <p>{t("estimationRequest.successPara2")}</p>
            <p>{t("estimationRequest.successPara3")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight" customize="mx-0" action={toOffer}>
                {t("estimationRequest.successBtnText")}
              </Button>
            </div>
          </div>
        ) : module === "eshop" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("shop.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("shop.successSubHeading")}</h4>
            <p>{t("shop.successPara1")}</p>
            <p>{t("shop.successPara2")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight" customize="mx-0" action={toOffer}>
                {t("shop.successBtnText")}
              </Button>
            </div>
          </div>
        ) : module === "upsell" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("track.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("track.successSubHeading")}</h4>
            <p>{t("track.successPara1")}</p>
            <p>{t("track.successPara2")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight" customize="mx-0" action={toOffer}>
                {t("track.successBtnText")}
              </Button>
            </div>
          </div>
        ) : module === "car-pickup" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("track.carPickSuccess.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("track.carPickSuccess.successSubHeading")}</h4>
            <p>{t("track.carPickSuccess.successPara1")}</p>
            <p>{t("track.carPickSuccess.successPara2")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight" customize="mx-0" action={toOffer}>
                {t("track.carPickSuccess.successBtnText")}
              </Button>
            </div>
          </div>
        ) : module === "quote" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("quotations.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("quotations.successSubHeading")}</h4>
            <p>{t("quotations.successPara1")}</p>
            <p>{t("quotations.successPara2")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="small-roundedRight"
                customize="mx-0"
                action={() =>
                  toUrlWithData(localeRoutes.track[currentLanguage()], {
                    tab: "quotations"
                  })
                }>
                {t("quotations.successBtnText")}
              </Button>
            </div>
          </div>
        ) : module === "subscription" ? (
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("subscription.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("subscription.successSubHeading")}</h4>
            <p>{t("subscription.successPara1")}</p>
            <p>{t("subscription.successPara2")}</p>
            <div className="d-flex justify-content-center mt-4">
              <Button type="small-roundedRight" customize="mx-0" action={toOffer}>
                {t("subscription.successBtnText")}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SuccessScreen;
