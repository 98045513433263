import React, { useState, useEffect, useRef } from "react";
import { Form, Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services";
import { automateOTPFields, concatOTPDigits, selectInputText } from "../../util/common";
import { useDispatch } from "react-redux";
import { saveUser } from "../../redux/reducers/userReducer";
import NotificationHelper from "./NotificationHelper";
import { Errors } from "../../util/errorMessage";
import { authOTPValidation } from "../../util/validations";
import { persistor } from "../../redux/store";

const OtpStep = ({ setOtpForm, setShowLoader, mobilePhone }) => {
  const { t } = useTranslation();

  const [lastOtp, setLastOtp] = useState("");
  const [showError, setShowError] = useState("");

  const dispatch = useDispatch();

  const otpFirstDigit = useRef();
  const otpSecondDigit = useRef();
  const otpThirdDigit = useRef();
  const otpFourthDigit = useRef();

  useEffect(() => {
    otpFirstDigit.current.focus();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setShowLoader(true);

    const code = concatOTPDigits(values);

    if (code === lastOtp) {
      setShowError(true);
      setShowLoader(false);
      return;
    }

    setShowError(false);
    setLastOtp(code);

    try {
      const verifyResponse = await UserService.verifyUser(code, mobilePhone);
      if (verifyResponse && verifyResponse.message === "user.verified") {
        // setUserData(verifyResponse?.user);
        dispatch(saveUser(verifyResponse?.user));
        await persistor.flush();
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
      const key = e?.response?.data?.key || "UNEXPECTED_ERROR";
      if (Errors[e?.response?.data?.key] !== undefined) {
        NotificationHelper.error(t(Errors[key]));
        setLastOtp("");
        return;
      }
      setShowError(true);
    } finally {
      setSubmitting(false);
      setShowLoader(false);
    }
  };

  return (
    <Formik
      initialValues={{ code1: "", code2: "", code3: "", code4: "" }}
      validationSchema={authOTPValidation}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <div className="container">
            <h4 className="text-sm-center mt-5 mb-1">{t("estimationRequest.verifyAccount")}</h4>
            <p className="text-sm-center mb-4">
              {t("estimationRequest.receiveActivationCodePara")}
            </p>

            <div className="row justify-content-center otp-inp-group">
              <div className="col-3 col-sm-2 col-lg-2 col-xl-1 mb-2">
                <Field
                  type="number"
                  id="code1"
                  name="code1"
                  placeholder="X"
                  maxLength={1}
                  pattern="[0-9]*"
                  innerRef={otpFirstDigit}
                  className="form-control text-center"
                  onInput={(e) => automateOTPFields(e, otpSecondDigit, setShowError)}
                  onFocus={selectInputText}
                />
              </div>
              <div className="col-3 col-sm-2 col-lg-2 col-xl-1 mb-2">
                <Field
                  type="number"
                  id="code2"
                  name="code2"
                  placeholder="X"
                  maxLength={1}
                  pattern="[0-9]*"
                  innerRef={otpSecondDigit}
                  className="form-control text-center"
                  onInput={(e) => automateOTPFields(e, otpThirdDigit, setShowError)}
                  onKeyDown={(e) => automateOTPFields(e, otpFirstDigit)}
                  onFocus={selectInputText}
                />
              </div>
              <div className="col-3 col-sm-2 col-lg-2 col-xl-1 mb-2">
                <Field
                  type="number"
                  id="code3"
                  name="code3"
                  placeholder="X"
                  maxLength={1}
                  pattern="[0-9]*"
                  innerRef={otpThirdDigit}
                  className="form-control text-center"
                  onInput={(e) => automateOTPFields(e, otpFourthDigit, setShowError)}
                  onKeyDown={(e) => automateOTPFields(e, otpSecondDigit)}
                  onFocus={selectInputText}
                />
              </div>
              <div className="col-3 col-sm-2 col-lg-2 col-xl-1 mb-2">
                <Field
                  type="number"
                  id="code4"
                  name="code4"
                  placeholder="X"
                  maxLength={1}
                  pattern="[0-9]*"
                  innerRef={otpFourthDigit}
                  className="form-control text-center"
                  onInput={(e) => automateOTPFields(e, null, setShowError)}
                  onKeyDown={(e) => automateOTPFields(e, otpThirdDigit)}
                  onFocus={selectInputText}
                />
              </div>

              {showError && (
                <span className="red-color text-center mt-2">
                  {t("estimationRequest.inValidCode")}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button type="button" className="back-btn" onClick={() => setOtpForm(false)}>
              {t("estimationRequest.previous")}
            </button>
            <input
              type="submit"
              className="mx-0"
              disabled={
                values.code1 === "" ||
                values.code2 === "" ||
                values.code3 === "" ||
                values.code4 === ""
              }
              value={t("estimationRequest.verifyButtonText")}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OtpStep;
