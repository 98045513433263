import { useNavigate } from "react-router-dom";
import { currentLanguage as lang } from "../util/common";
import { contactUs } from "../util/constants";

const useAppNavigation = () => {
  const navigate = useNavigate();

  const toHome = () => navigate(localeRoutes.home[lang()]);

  const toLocations = () => navigate(localeRoutes.locations[lang()]);

  const toBringIt = () => navigate(localeRoutes.bringIt[lang()]);

  const toScheduleAppointment = () => navigate(localeRoutes.appointment[lang()]);

  const toFastDrop = (id) => navigate(localeRoutes.fastDrop[lang()], { state: { id } });

  const toTrackYourProgress = () => navigate(localeRoutes.track[lang()]);

  const toEstimationWelcome = () => navigate(localeRoutes.repairEstimate[lang()]);

  const toEstimationRequest = () => navigate(localeRoutes.repairEstimateRequest[lang()]);

  const toMyAccount = (tabKey, subscriptionId) => {
    const activeTab = tabKey ? "/" + tabKey : "";
    const subscriptionRef = tabKey === "subscription" && subscriptionId ? "/" + subscriptionId : "";
    navigate(localeRoutes.myAccount[lang()] + activeTab + subscriptionRef);
  };

  const toFindUs = () => navigate(localeRoutes.findus[lang()]);

  const toCustomerCare = () => navigate(localeRoutes.customerCare[lang()]);

  const toBecomeAPartner = () => navigate(localeRoutes.becomeAPartner[lang()]);

  const toJoinFamily = () => navigate(localeRoutes.joinFamily[lang()]);

  const toShop = () => navigate(localeRoutes.eshop[lang()]);

  const toShopCheckout = () => navigate(localeRoutes.shopCheckout[lang()]);

  const toSuccessScreen = (data) => navigate(localeRoutes.success[lang()], { state: data });

  const toServices = (e, id) => {
    if (!id && e && (e.target.nodeName === "svg" || e.target.nodeName === "path")) {
      mobileDropdownToggler(e);
    } else {
      navigate(id ? `${localeRoutes.services[lang()]}/${id}` : localeRoutes.services[lang()]);
    }
  };
  const toCatalogs = (id) => {
    navigate(id ? `${localeRoutes.catalog[lang()]}/${id}` : localeRoutes.catalog[lang()]);
  };

  const toOffer = (e, id) => {
    if (!id && e && (e.target.nodeName === "svg" || e.target.nodeName === "path")) {
      mobileDropdownToggler(e);
    } else {
      navigate(id ? `${localeRoutes.offers[lang()]}/${id}` : localeRoutes.offers[lang()]);
    }
  };

  const toContactUs = ({ e, path }) => {
    if (!path) mobileDropdownToggler(e);
    else navigate(`/${path}`);
  };

  const toAccessibilityPolicy = () => navigate(localeRoutes.accessibilityPolicy[lang()]);

  const toPrivacyAndPolicy = () => navigate(localeRoutes.privacyPolicy[lang()]);

  const toRequiredDocuments = () => navigate(localeRoutes.requiredDocuments[lang()]);

  const toSitemap = () => navigate(localeRoutes.sitemap[lang()]);

  const toTermsOfUse = () => navigate(localeRoutes.termsAndConditions[lang()]);

  const toNotFound = () => navigate(localeRoutes.notFound[lang()]);

  const toBlogs = () => navigate(localeRoutes.knowledgeHub[lang()]);

  const toQuoteServiceDetail = (slug) => navigate(`${localeRoutes.quoteService[lang()]}/${slug}`);

  const toRequestAQuote = (slug) => navigate(`${localeRoutes.requestAQuote[lang()]}/${slug}`);

  const toSubscribeOffer = (slug) => navigate(`${localeRoutes.subscribe[lang()]}/${slug}`);

  const toSelectService = () => navigate(localeRoutes.selectService[lang()]);

  const toUrl = (route) => navigate(route);

  const toUrlWithData = (route, data) => navigate(route, { state: data });

  // Mobile view menu bar: To open and close dropdown that have sub menus
  const mobileDropdownToggler = (e) => {
    if (window.innerWidth > 991) return;
    const nodeConfig = {
      svg: {
        menuAddress: e.target.parentElement.nextSibling,
        element: e.target
      },
      path: {
        menuAddress: e.target.parentElement.parentElement.nextSibling,
        element: e.target.parentElement
      }
    };
    const menu = nodeConfig[e.target.nodeName].menuAddress;
    if (!menu.classList.contains("expanded")) {
      nodeConfig[e.target.nodeName].element.style.transform = "rotate(180deg)";
      menu.style.height = "auto";
      menu.classList.add("expanded");
    } else {
      nodeConfig[e.target.nodeName].element.style.transform = "rotate(0)";
      menu.style.height = "0";
      menu.classList.remove("expanded");
    }
  };

  const getLocaleRoute = (path = "", fallbackPath = undefined) => {
    if (!path) return fallbackPath || localeRoutes.notFound[lang()];
    return lang() === "en" ? `/en${path}` : `${path}`;
  };

  const localeRoutes = {
    home: {
      en: "/en",
      ar: "/"
    },
    services: {
      en: "/en/services",
      ar: "/services"
    },
    locations: {
      en: "/en/locations",
      ar: "/locations"
    },
    catalog: {
      en: "/en/catalogs",
      ar: "/catalogs"
    },
    sitemap: {
      en: "/en/sitemap",
      ar: "/sitemap"
    },
    requiredDocuments: {
      en: "/en/required-documents",
      ar: "/required-documents"
    },
    termsAndConditions: {
      en: "/en/terms-and-conditions",
      ar: "/terms-and-conditions"
    },
    accessibilityPolicy: {
      en: "/en/accessibility-policy",
      ar: "/accessibility-policy"
    },
    privacyPolicy: {
      en: "/en/privacy-policy",
      ar: "/privacy-policy"
    },
    findus: {
      en: "/en/findus",
      ar: "/findus"
    },
    bringIt: {
      en: "/en/bring-it-to-the-expert",
      ar: "/bring-it-to-the-expert"
    },
    track: {
      en: "/en/track-your-repair-progress",
      ar: "/track-your-repair-progress"
    },
    cookiePolicy: {
      en: "/en/cookie-policy",
      ar: "/cookie-policy"
    },
    knowledgeHub: {
      en: "/en/knowledgehub",
      ar: "/knowledgehub"
    },
    appointment: {
      en: "/en/schedule-appointment",
      ar: "/schedule-appointment"
    },
    offers: {
      en: "/en/offers",
      ar: "/offers"
    },
    quoteService: {
      en: "/en/quote-service",
      ar: "/quote-service"
    },
    requestAQuote: {
      en: "/en/request-a-quote",
      ar: "/request-a-quote"
    },
    kaizen: {
      en: "/en/kaizen",
      ar: "/kaizen"
    },
    signupForservice: {
      en: "/en/signup-for-service",
      ar: "/signup-for-service"
    },
    myAccount: {
      en: "/en/my-account",
      ar: "/my-account"
    },
    repairEstimate: {
      en: "/en/repair-estimate",
      ar: "/repair-estimate"
    },
    repairEstimateRequest: {
      en: "/en/repair-estimate/request",
      ar: "/repair-estimate/request"
    },
    estimateTermsAndConditions: {
      en: "/en/estimation-terms-and-conditions",
      ar: "/estimation-terms-and-conditions"
    },
    success: {
      en: "/en/success",
      ar: "/success"
    },
    customerCare: {
      en: `/en/${contactUs[0]}`,
      ar: `/${contactUs[0]}`
    },
    becomeAPartner: {
      en: `/en/${contactUs[1]}`,
      ar: `/${contactUs[1]}`
    },
    joinFamily: {
      en: `/en/${contactUs[2]}`,
      ar: `/${contactUs[2]}`
    },
    deleteAccount: {
      en: `/en/${contactUs[3]}`,
      ar: `/${contactUs[3]}`
    },
    eshop: {
      en: "/en/shop/products?city=jeddah&branch=63c10f6aa920ea32701f0b7a",
      ar: "/shop/products?city=jeddah&branch=63c10f6aa920ea32701f0b7a"
    },
    shopCheckout: {
      en: "/en/shop/checkout",
      ar: "/shop/checkout"
    },
    shopProduct: {
      en: "/en/shop/product",
      ar: "/shop/product"
    },
    subscribe: {
      en: "/en/subscribe",
      ar: "/subscribe"
    },
    subscriptionDetails: {
      en: "/en/subscription",
      ar: "/subscription"
    },
    selectService: {
      en: "/en/select-service",
      ar: "/select-service"
    },
    fastDrop: {
      en: "/en/appointment/fast-drop",
      ar: "/appointment/fast-drop"
    },
    insurance: {
      en: "/en/insurance",
      ar: "/insurance"
    },
    notFound: {
      en: "/en/404",
      ar: "/404"
    }
  };

  return {
    navigate,
    toHome,
    toLocations,
    toBringIt,
    toScheduleAppointment,
    toFastDrop,
    toTrackYourProgress,
    toEstimationWelcome,
    toEstimationRequest,
    toMyAccount,
    toFindUs,
    toCustomerCare,
    toBecomeAPartner,
    toJoinFamily,
    toShop,
    toShopCheckout,
    toSuccessScreen,
    toServices,
    toOffer,
    toContactUs,
    toAccessibilityPolicy,
    toPrivacyAndPolicy,
    toRequiredDocuments,
    toSitemap,
    toTermsOfUse,
    toNotFound,
    toBlogs,
    toQuoteServiceDetail,
    toRequestAQuote,
    toSubscribeOffer,
    toSelectService,
    toUrl,
    toUrlWithData,
    getLocaleRoute,
    localeRoutes,
    toCatalogs
  };
};

export default useAppNavigation;
