import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { UserService } from "../../services";
import { dispatchStorage } from "../../util/common";
import { isAccessComponent } from "../../util/constants";

const PasswordModal = () => {
  const [isInCorrectPassword, setInCorrectPassword] = useState(false);

  const getValidationSchema = () => {
    setInCorrectPassword(false);

    return Yup.object().shape({
      password: Yup.string().required("Required")
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await UserService.validateWebPassword(values.password);
      if (response.statusCode === 200) {
        dispatchStorage("token", "TEMP_TOKEN");
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
      setInCorrectPassword(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal show={!isAccessComponent} centered>
      <Formik
        initialValues={{
          password: ""
        }}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}>
        {({ errors, touched }) => (
          <Form action="" id="PasswordForm">
            <Modal.Body>
              <p>Please Enter Password</p>
              <Field
                className="form-control"
                type="password"
                id="password"
                name="password"
                placeholder="xxxxxxx"
              />
              {errors.password && touched.password && (
                <span className="red-color">{" * " + errors.password}</span>
              )}
              {isInCorrectPassword && <span className="red-color">Invalid password</span>}

              <div>
                <Button
                  type="submit"
                  style={{
                    border: 0,
                    float: "right",
                    margin: "15px 0",
                    padding: "5px 15px",
                    background: "#ffb71b"
                  }}
                  variant="primary">
                  Verify
                </Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordModal;
