import React from "react";
import { Icon } from "./";
import { useTranslation } from "react-i18next";
import { useAppNavigation, useWindowWide } from "../../hooks";
import { currentLanguage } from "../../util/common";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomTabNavigations = () => {
  const { t } = useTranslation();
  const { localeRoutes, toHome, toOffer, toBringIt, toServices, toTrackYourProgress } =
    useAppNavigation();
  const { pathname } = useLocation();
  const wide = useWindowWide();
  const pages = useSelector((state) => state.content.pages) || [];

  const lang = currentLanguage();
  const onHome = pathname === "/en" || pathname === "/" || pathname === "/en/";
  const onService = pathname.includes(localeRoutes.services[lang]);
  const onOffer = pathname.includes(localeRoutes.offers[lang]);
  const onSchedule = pathname.includes(localeRoutes.bringIt[lang]);
  const onTrack = pathname.includes(localeRoutes.track[lang]);

  const tabsNavigationList = [
    {
      accessKey: "home",
      iconName: "home",
      label: t("footer.home"),
      link: toHome
    },
    {
      accessKey: "services",
      iconName: "services",
      label: t("header.ourServices"),
      link: () => toServices()
    },
    {
      accessKey: "offers",
      iconName: "offers",
      label: t("header.offers"),
      link: () => toOffer()
    },
    {
      accessKey: "schedule",
      iconName: "book",
      label: t("header.bringItToTheExpert"),
      link: toBringIt
    },
    {
      accessKey: "track",
      iconName: "track",
      label: t("header.trackYourRepairProgress"),
      link: toTrackYourProgress
    }
  ].filter(({ accessKey }) => pages.includes(accessKey));

  return (
    <>
      {tabsNavigationList.length && wide <= 991 ? (
        <nav className="bottom-nav">
          {tabsNavigationList.map((tabItem, i) => {
            if (!pages?.includes(tabItem.iconName)) return;

            const activeMenu =
              (onHome && i === 0) ||
              (onService && i === 1) ||
              (onOffer && i === 2) ||
              (onSchedule && i === 3) ||
              (onTrack && i === 4);

            return (
              <div
                key={tabItem.accessKey}
                onClick={tabItem.link}
                className={`bottom-tab-container ${activeMenu}`}>
                <Icon className="bottom-tab-icon" iconName={tabItem.iconName} />
                <span className="bottom-tab-text">{tabItem.label}</span>
              </div>
            );
          })}
        </nav>
      ) : null}
    </>
  );
};

export default React.memo(BottomTabNavigations);
