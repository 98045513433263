import React from "react";
import { Modal } from "react-bootstrap";
import { Icon, Button } from "../shared";

const DisclaimerModal = ({
  show,
  close,
  iconName,
  headingText,
  descriptionText,
  agreeButtonText,
  agreeButtonClass,
  handleAgreeAction,
  children
}) => {
  return (
    <Modal show={show} onHide={close} centered={true} size="lg">
      <Modal.Body className="autohub-modal disclaimer-modal">
        <div className="close-icon">
          <Icon iconName="xmark" onClick={close} />
        </div>
        <div className="mt-4">
          {iconName && <Icon iconName={iconName} className="alert-icon" />}
          {headingText && <h5>{headingText}</h5>}
          {descriptionText && <p>{descriptionText}</p>}
          {children}
          {agreeButtonText && (
            <div className="mt-4">
              <Button
                type="small-roundedRight"
                className={agreeButtonClass}
                action={handleAgreeAction}>
                {agreeButtonText}
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisclaimerModal;
