import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../shared";
import { useAppNavigation } from "../../hooks";

const NotFound = () => {
  const { t } = useTranslation();
  const { toHome } = useAppNavigation();

  return (
    <div id="main">
      <div className="fof">
        <h1>{t("errorPage.pageHeading")}</h1>
        <h2>{t("errorPage.pageSubHeading")}</h2>

        <Button action={toHome} type="big-roundedRight" role="menuitem">
          {t("errorPage.buttonText")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
