import axios from "axios";
import { BaseURL } from "../config/webService";
import { loadStorage } from "../util/common";
import { UserService } from "../services";
export default class BaseService {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        config.baseURL = BaseURL;
        if (loadStorage("token")) {
          config.headers.authorization = "Bearer " + loadStorage("token");
        }
        return config;
      },
      function (error) {
        console.error("axiosRequestError", error);
        console.error(error);
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (
          error.response?.data.error?.name === "TokenExpiredError" ||
          error.response?.data.error?.name === "JsonWebTokenError" ||
          error.response?.data.error === "error.sessionExpired" ||
          error.response?.data.error === "error.accessDenied"
        ) {
          UserService.logOutUser();
        }
        console.error(error);
        return Promise.reject(error);
      }
    );
  }
}
