import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, navigationWithEvents, truncateText } from "../../util/common";
import { useAppNavigation } from "../../hooks";

const Card = ({ type, title, image, route, customClassName, service }) => {
  const { t } = useTranslation();
  const { localeRoutes, toUrl, toUrlWithData } = useAppNavigation();

  const language = currentLanguage();
  const defaultClassName = customClassName || `col-md-6 col-lg-4 col-xl-3`;
  const LocaleHeading = () => <h3>{truncateText(title?.["value_" + language], 50)}</h3>;

  return (
    <div className={defaultClassName}>
      <div
        className="service-card"
        style={{
          background: `url(${GET_MEDIA_URL + (image?.[language] || image)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}>
        <div className="offer-meta">
          <LocaleHeading />
          <div className="card-navs">
            {service ? (
              <Button
                type="blue-roundedRight"
                action={() => toUrlWithData(localeRoutes.appointment[language], { service })}>
                {t("services.book")}
              </Button>
            ) : (
              <Button
                type="blue-roundedRight"
                action={() =>
                  navigationWithEvents(
                    type === "offers" ? "Header-Kaizen" : "Header-Services",
                    type === "offers" ? "Kaizen Details" : "Services Details",
                    () => toUrl(route)
                  )
                }>
                {type === "offers" ? t("offer.seeMore") : t("services.seeMore")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Card);
