import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isAccessComponent } from "../../util/constants";
import { PagesService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { savePages } from "../../redux/reducers/contentReducer";
import { useAppNavigation } from "../../hooks";

const PrivateRoute = ({ element: Element, accessKey, ...rest }) => {
  const dispatch = useDispatch();
  const { toNotFound } = useAppNavigation();
  const pages = useSelector((state) => state.content.pages);

  useEffect(() => {
    getPages();
  }, []);

  const getPages = async () => {
    try {
      const result = (await PagesService.getPages()) || [];
      const allowedPages = [...result, "other"];
      dispatch(savePages(allowedPages));
    } catch (e) {
      console.error(e);
    }
  };

  if (!pages || (isAccessComponent && pages.includes(accessKey))) {
    return <Element {...rest} />;
  } else if (!isAccessComponent) {
    return <RestrictionAlert />;
  } else {
    return toNotFound();
  }
};

const RestrictionAlert = () => {
  const { t } = useTranslation();
  return (
    <div id="main">
      <div className="fof">
        <h2>{t("home.noContent")}</h2>
      </div>
    </div>
  );
};

export default PrivateRoute;
