import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Offcanvas } from "react-bootstrap";
import { GET_MEDIA_URL } from "../../config/webService";
import { Button, Icon } from "./";
import { currentLanguage } from "../../util/common";
import {
  calculateTotal,
  editAddonsCart,
  handleAddDeleteItem,
  langConfig
} from "../../util/addonsUtils";

const AddonsCart = ({ cart, setCart, quantity, goForward }) => {
  const { t } = useTranslation();
  const lang = currentLanguage();

  const [cartShow, setCartShow] = useState(false);

  const handleCartShow = () => setCartShow(!cartShow);

  const handleCart = (item, type) => handleAddDeleteItem({ item, type, cart, setCart, t });
  const emptyCart = () => {
    setCart({ offers: [], products: [] });
    handleCartShow();
  };

  const subTotalPrice = calculateTotal([...cart.offers, ...cart.products], "price");

  return (
    <>
      <div className="user-cart" onClick={handleCartShow}>
        <Icon iconName="cart" />
        <span>{quantity}</span>
      </div>

      <Offcanvas show={cartShow} onHide={handleCartShow} placement={langConfig.drawerPlace[lang]}>
        <Offcanvas.Header>
          <h6>{t("addons.shoppingCart")}</h6>
          <Icon iconName="xmark" onClick={handleCartShow} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {quantity ? (
            <>
              {cart.offers.map((offer, key) => {
                return (
                  <div className="product" key={key}>
                    <div>
                      <p>{offer.name["value_" + lang]}</p>
                      <span className="price">
                        {offer.price} {t("addons.currency")}
                      </span>
                      <span className="remove" onClick={() => handleCart(offer, "offers")}>
                        <Icon iconName="trash" /> {t("addons.removeBtn")}
                      </span>
                    </div>
                    <div>
                      <img
                        src={
                          offer?.isRecommendedOffer
                            ? GET_MEDIA_URL + offer?.images[0]?.value
                            : GET_MEDIA_URL + offer["image_" + lang]
                        }
                      />
                    </div>
                  </div>
                );
              })}
              {cart.products.map((variant, key) => {
                if (!variant?.quantity) variant.quantity = 1;
                return (
                  <div className="product" key={key}>
                    <div>
                      <p>{variant.name["value_" + lang]}</p>
                      <div className="values">
                        <span className="price">
                          {variant.price} {t("addons.currency")}
                        </span>
                        <span className="variant-quantity">
                          <Icon iconName="xmark" />
                        </span>
                        <span className="counter">
                          <span
                            className={`${variant?.quantity > 1}`}
                            onClick={() =>
                              editAddonsCart({ type: "-", item: variant, cart, setCart, t })
                            }>
                            <Icon iconName="minus" />
                          </span>
                          {variant?.quantity || 1}
                          <span
                            className={`${
                              variant?.quantity < variant?.totalRemainingQuantity
                              // variant?.quantity < 10
                            }`}
                            onClick={() =>
                              editAddonsCart({ type: "+", item: variant, cart, setCart, t })
                            }>
                            <Icon iconName="plus" />
                          </span>
                        </span>
                      </div>

                      <span className="remove" onClick={() => handleCart(variant, "products")}>
                        <Icon iconName="trash" /> {t("addons.removeBtn")}
                      </span>
                    </div>
                    <div>
                      <img src={GET_MEDIA_URL + variant.image} />
                    </div>
                  </div>
                );
              })}
              <div className="cart-subtotal">
                <label>{t("addons.subtotal")}</label>
                <span>
                  {subTotalPrice} {t("addons.currency")}
                </span>
              </div>
              <div className="controls">
                <Button type="small-roundedLeft" action={emptyCart}>
                  {t("addons.emptyCartBtn")}
                </Button>
                <Button type="blue-roundedRight" action={goForward} disabled={!quantity}>
                  {t("addons.checkoutBtn")}
                </Button>
              </div>
            </>
          ) : (
            <h6>{t("addons.nothingInCart")}</h6>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default React.memo(AddonsCart);
