import { useEffect, useState } from "react";
import WR360 from "@webrotate360/imagerotator";
import "@webrotate360/imagerotator/build/css/all.css";
import { getImageKey } from "../util/estimationUtils";

const use360Car = ({
  dependencies = [],
  doStopRender = false,
  rotatedOnce,
  setRotatedOnce,
  openUploadModal
}) => {
  const [apiConfig, setApiConfig] = useState({});

  useEffect(() => {
    if (doStopRender) return;

    let rotatorApi;
    const viewer = WR360.ImageRotator.Create("webrotate360");
    viewer.settings.configFileURL = "/autohubcar360.xml";
    viewer.settings.graphicsPath = "/graphics";
    viewer.settings.responsiveBaseWidth = 800;
    viewer.settings.responsiveMinHeight = window.innerWidth < 415 ? 230 : 330;
    viewer.settings.apiReadyCallback = (api) => {
      api.hotspots.onAction((e) => {
        const key = getImageKey(e);
        openUploadModal(key);
      });

      !rotatedOnce && api.toolbar.rotateOnce(2);
      setRotatedOnce && setRotatedOnce(true);

      setApiConfig(api);
      rotatorApi = api;
    };
    viewer.runImageRotator();

    return () => rotatorApi?.delete();
  }, [...dependencies]);

  return apiConfig;
};

export default use360Car;
