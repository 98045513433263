import { useState } from "react";

const useSlicedData = (list = [], range = 3) => {
  const data = [...list];
  const [slicedRange, setSlicedRange] = useState(range);

  const getMore = () => setSlicedRange(slicedRange + range);

  const result = {
    data: data.slice(0, slicedRange),
    hasMore: slicedRange < data.length,
    getMore
  };
  return result;
};

export default useSlicedData;
