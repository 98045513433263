import React from "react";
import { Modal } from "react-bootstrap";
import frontImage from "../../images/front.png";
import rearImage from "../../images/back.png";
import rightImage from "../../images/right.png";
import leftImage from "../../images/left.png";
import wideAngle from "../../images/wideAngle.png";
import closeAngleOne from "../../images/closeAngleOne.png";
import closeAngleTwo from "../../images/closeAngleTwo.png";
import { useTranslation } from "react-i18next";
import { Icon } from "./";

const ImagesGuideModal = ({ show, close, isVehicle }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} centered size="xl">
      <Modal.Body className="autohub-modal">
        <div className="close-icon">
          <Icon iconName="xmark" onClick={close} />
        </div>

        <div className="image-guide-modal-header">
          <h4>{t("estimationRequest.stepsToUpload")}</h4>
          <p>{t("estimationRequest.para")}</p>
        </div>

        <div className="image-guidance-wrapper">
          <div className="row justify-content-center">
            {isVehicle ? (
              <>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.front")}</h6>
                    <img src={frontImage} />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.right")}</h6>
                    <img src={rightImage} />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.rear")}</h6>
                    <img src={rearImage} />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.left")}</h6>
                    <img src={leftImage} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.closeAngleOne")}</h6>
                    <img src={closeAngleOne} />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.closeAngleTwo")}</h6>
                    <img src={closeAngleTwo} />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 mb-3">
                  <div className="carWrapper">
                    <h6>{t("estimationRequest.wideAngle")}</h6>
                    <img src={wideAngle} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImagesGuideModal;
