import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { GET_MEDIA_URL } from "../../config/webService";
import { useTranslation } from "react-i18next";
import HomeService from "../../services/homeService";
import { times } from "lodash";
import { Icon } from "../shared";

const Headlines = ({ lang, setLoading }) => {
  const { t } = useTranslation();
  const [headlines, setHeadlines] = useState([]);
  // const { media } = useSelector((state) => state.content);

  useEffect(() => {
    getHeadlines();
  }, []);

  const getHeadlines = async () => {
    setLoading(true);
    try {
      const headline = await HomeService.getHeadlines();
      if (headline) setHeadlines(headline);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {headlines.length ? (
        <div className="headlines">
          <Carousel interval={6000} indicators={false} controls={false}>
            {headlines?.map((data, i) => {
              const description =
                window.innerWidth > 575
                  ? data.description?.[lang]
                  : data.description?.[lang].slice(0, 65) + "...";

              return (
                <Carousel.Item key={i}>
                  <div>
                    <div className="brand-logo">
                      <div className="img">
                        <img
                          src={GET_MEDIA_URL + data.image?.[lang]}
                          width="140"
                          height="38"
                          alt={data.title?.en}
                        />
                      </div>
                      <div className="rating">
                        {times(data?.rating, (i) => (
                          <Icon iconName="star" key={i} />
                        ))}
                      </div>
                    </div>
                    <div className="story">
                      <p>{data.title?.[lang]}</p>
                      <span>
                        {description} <Link to={data.link}>{t("home.headlineReadMore")}</Link>
                      </span>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      ) : null}

      {/* <div className="headlines pink">
        <div className="static-headline">
          <div className="brand-logo">
            <div>
              <img src={PinkRibbon} width="140" height="38" alt="Breast cancer - Autohub" />
            </div>
            <div>
              <p>
                <span>autohub </span> supports
              </p>
              <strong>
                <span>Breast Cancer</span> AWARENESS MONTH
              </strong>
            </div>
          </div>
          <div className="details">
            <p>FREE</p>
            <span>
              21-point safety check for our valued female guests.{" "}
              <Link to={media.breastCancerOffer}>Find out more and Book</Link>
            </span>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Headlines;
