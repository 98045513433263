import React, { useEffect, useState } from "react";
import moment from "moment";
import { ToastContainer, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon, Lightbox } from "./";
import { GET_MEDIA_URL, GET_VIDEO_URL } from "../../config/webService";
import { useSlicedData } from "../../hooks";

const Notifications = ({
  messages = [],
  showNoResults = true,
  allowSlicing = false,
  parentRef
}) => {
  const { t } = useTranslation();
  const [lighboxData, setLightBoxData] = useState(null);
  messages = useSlicedData(messages, allowSlicing ? 4 : messages.length);

  useEffect(() => {
    if (parentRef) parentRef.current.style.position = lighboxData ? "unset" : "sticky";
  }, [lighboxData]);

  const openLightBox = ({ images, video }, start) => {
    const sources = [];
    if (images)
      for (const image of images) {
        sources.push({
          type: "photo",
          url: GET_MEDIA_URL + image?.value
        });
      }
    if (video)
      sources.push({
        type: "video",
        url: GET_VIDEO_URL + video + "&output=embed"
      });

    setLightBoxData({ media: sources, start });
  };

  const closeLightBox = () => setLightBoxData(null);

  return (
    <>
      {messages.data?.length ? (
        <>
          <ToastContainer position="middle-center">
            {messages.data?.map((msg, i) => {
              const commentDate = moment(msg.createdTime).format("MMM D YYYY, h:mma");
              return (
                <Toast className="d-inline-block admin-message" key={i}>
                  <Toast.Header
                    closeButton={false}
                    className={`${msg.seen ? "read-notification" : "unread-notification"}`}>
                    <strong className="me-auto">{t("track.autohub")}</strong>
                    <small>{commentDate}</small>
                  </Toast.Header>
                  <Toast.Body>
                    {(msg.body || msg.notes) && <p className="mb-0">{msg.body || msg.notes}</p>}
                    {msg.images?.length || msg.video ? (
                      <div className={`received-media ${!msg.notes ? "mt-0" : ""}`}>
                        {msg.images && (
                          <span onClick={() => openLightBox(msg, 0)}>
                            <Icon iconName="image" /> {t("track.viewPicture")}
                          </span>
                        )}
                        {msg.video && (
                          <span onClick={() => openLightBox(msg, msg.images.length)}>
                            <Icon iconName="video" /> {t("track.viewVideo")}
                          </span>
                        )}
                      </div>
                    ) : null}
                  </Toast.Body>
                </Toast>
              );
            })}
          </ToastContainer>

          {allowSlicing && messages.hasMore && (
            <span className="view-more-btn" onClick={messages.getMore}>
              {t("addons.viewMoreAddonsBtn")}
            </span>
          )}

          <Lightbox data={lighboxData} onClose={closeLightBox} />
        </>
      ) : (
        <>
          {showNoResults && (
            <div className="no-comments">
              <h4 className="no-data">{t("track.noComments")}</h4>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(Notifications);
