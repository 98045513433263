import React, { useState, useEffect } from "react";
import { scrollPageTop } from "../../util/common";
import { Icon } from "../shared";

const GoToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) setShowButton(true);
      else setShowButton(false);
    });
  }, []);

  return (
    <>
      {showButton && (
        <div className="back-to-top" onClick={scrollPageTop}>
          <Icon iconName="arrowUp" />
        </div>
      )}
    </>
  );
};

export default React.memo(GoToTop);
