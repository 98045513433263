// Multilingual logos
import logo from "../images/ah-logo.webp";
import logoArabic from "../images/ah-logo-ar.webp";
import VisaLogo from "../images/visacard-logo.webp";
import MadaLogo from "../images/madacard-logo.webp";
import MasterLogo from "../images/mastercard-logo.webp";
import TamaraLogoEn from "../images/tamara-logo-en.webp";
import TamaraLogoAr from "../images/tamara-logo-ar.webp";
import ALJLogo from "../images/ALJ.png";
import { loadStorage } from "./common";

// Button configurations
export const SMALL_ROUNDED_RIGHT = "small-roundedRight";
export const SMALL_ROUNDED_LEFT = "small-roundedLeft";
export const BIG_ROUNDED_RIGHT = "big-roundedRight";
export const BIG_ROUNDED_LEFT = "big-roundedLeft";
export const BG_WHITE = "white";
export const BLUE_ROUNDED_LEFT = "blue-roundedLeft";
export const BLUE_ROUNDED_RIGHT = "blue-roundedRight";

// Contact us: Routes
export const contactUs = Object.freeze([
  "customer-care",
  "partner-with-autohub",
  "join-autohub-family"
  // "delete-account"
]);

// Locations: Map styling to dark theme
export const mapAreasStyle = Object.freeze([
  { elementType: "geometry", stylers: [{ color: "#3d5f79" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#3d5f79" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#a6a6a6" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#a6a6a6" }]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#a6a6a6" }]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#3d5f79" }]
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#3d5f79" }]
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#466e8c" }]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }]
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }]
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#a6a6a6" }]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#466e8c" }]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }]
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#466e8c" }]
  }
]);

// Schedule Appointment: Mr/MS input field
export const userTitles = Object.freeze({
  Mr: { en: "Mr", ar: "السيد" },
  Ms: { en: "Ms", ar: "آنسة" }
});

// Find us: Locations data
export const locations = Object.freeze([
  {
    value_en: "Jeddah - Bawadi",
    value_ar: "جدة - حي البوادي",
    address: "https://goo.gl/maps/dQtG37977MSwVuqG6"
  },
  {
    value_en: "Jeddah - Alsafa ",
    value_ar: "جدة - حي الصفا",
    address: "https://goo.gl/maps/mLfqi6Zxc1YUo2i9A"
  },
  {
    value_en: "Dammam",
    value_ar: "الدمام",
    address: "https://goo.gl/maps/Mk44bfZso9aQkNJR9"
  },
  {
    value_en: "Riyadh - Faisaliya North",
    value_ar: "الرياض - الفيصلية شمال",
    address: "https://goo.gl/maps/HJuGjPhhDDJdk9WD9"
  },
  {
    value_en: "Riyadh - Faisaliya South",
    value_ar: "الرياض - الفيصلية جنوب",
    address: "https://goo.gl/maps/2hDSgAx1a9rRfZUc7"
  },
  {
    value_en: "Madinah",
    value_ar: "المدينة المنورة",
    address: "https://goo.gl/maps/EqFsLa8LfWQbj3wa6"
  },
  {
    value_en: "Makkah",
    value_ar: "مكة المكرمة",
    address: "https://goo.gl/maps/2Gz76brKQZqSYs8C6"
  },
  {
    value_en: "Taif",
    value_ar: "الطائف",
    address: "https://goo.gl/maps/Kv6UkxEg2QDfmfgR7"
  }
]);

// Imports of english and arabic logo
export const logoSet = Object.freeze({
  en: logo,
  ar: logoArabic
});

// Imports of english and arabic tamara logo
export const tamaraLogoSet = Object.freeze({
  en: TamaraLogoEn,
  ar: TamaraLogoAr
});
export const ALJLogoSet = Object.freeze({
  en: ALJLogo,
  ar: ALJLogo
});

// All possible time slots for schedule appointment
export const allTimes = Object.freeze([
  "12:00am",
  "12:30am",
  "1:00am",
  "1:30am",
  "2:00am",
  "2:30am",
  "3:00am",
  "3:30am",
  "4:00am",
  "4:30am",
  "5:00am",
  "5:30am",
  "6:00am",
  "6:30am",
  "7:00am",
  "7:30am",
  "8:00am",
  "8:30am",
  "9:00am",
  "9:30am",
  "10:00am",
  "10:30am",
  "11:00am",
  "11:30am",
  "12:00pm",
  "12:30pm",
  "1:00pm",
  "1:30pm",
  "2:00pm",
  "2:30pm",
  "3:00pm",
  "3:30pm",
  "4:00pm",
  "4:30pm",
  "5:00pm",
  "5:30pm",
  "6:00pm",
  "6:30pm",
  "7:00pm",
  "7:30pm",
  "8:00pm",
  "8:30pm",
  "9:00pm",
  "9:30pm",
  "10:00pm",
  "10:30pm",
  "11:00pm",
  "11:30pm"
]);

// Schedule appointment: Allow only these file formats
export const allowedFormats = ["image/png", "image/jpg", "image/jpeg", "image/bmp"];

// Track your repair progress: To show highlighted status of car repair and CND
export const repairStatus = Object.freeze({
  appointments: {
    awaiting_approval: 0,
    repair_in_progress: 1,
    quality_check: 2,
    repair_completed: 4
  },
  cnd_appointments: {
    notStarted: 0,
    pickedFromCustomer: 1,
    receivedAtCenter: 2,
    pickedFromCenter: 3,
    deliveredToCustomer: 4
  }
});

// Track your repair progress: Names of all possible status for car repair and CND
export const repairStatusNames = Object.freeze({
  appointments: [
    { name: "track.awaitingApproval", icon: "clock" },
    { name: "track.repairInProgress", icon: "tool" },
    { name: "track.qualityCheck", icon: "star" },
    { name: "track.repairCompleted", icon: "medal" }
  ],
  cnd_appointments: [
    { name: "track.pickedFromCustomer", icon: "car" },
    { name: "track.receivedAtCenter", icon: "warehouse" },
    { name: "track.pickedFromCenter", icon: "pickupTruck" },
    { name: "track.deliveredToCustomer", icon: "medal" }
  ]
});

// Track your repair progress (estimation): Names of all possible status for Estimation
export const estimationStatus = Object.freeze({
  "Estimation Requested": 0,
  "Estimation In Progress": 1,
  "Estimation Completed": 3,
  "Estimation Closed": 3
});

export const quotationStatus = Object.freeze({
  Requested: 0,
  "In Progress": 1,
  Completed: 3
});

// Estimation request: Damage months
export const damageMonths = Object.freeze([
  { _id: "January", value_en: "January", value_ar: "يناير" },
  { _id: "Febuary", value_en: "Febuary", value_ar: "فبراير" },
  { _id: "March", value_en: "March", value_ar: "مارس" },
  { _id: "April", value_en: "April", value_ar: "أبريل" },
  { _id: "May", value_en: "May", value_ar: "مايو" },
  { _id: "June", value_en: "June", value_ar: "يونيو" },
  { _id: "July", value_en: "July", value_ar: "يوليو" },
  { _id: "August", value_en: "August", value_ar: "أغسطس" },
  { _id: "September", value_en: "September", value_ar: "سبتمبر" },
  { _id: "October", value_en: "October", value_ar: "أكتوبر" },
  { _id: "November", value_en: "November", value_ar: "نوفمبر" },
  { _id: "December", value_en: "December", value_ar: "ديسمبر" }
]);

// Estimation request: Damage years
export const damageYears = Object.freeze([
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" }
]);

// Estimation request: View for each next point on 360 Auto rotate
export const carRotatorConfig = Object.freeze({
  Front: ["Right", "Rear", "Left"],
  Rear: ["Left", "Front", "Right"],
  Right: ["Rear", "Left", "Front"],
  Left: ["Front", "Right", "Rear"]
});

// Global: get location origin
export const domainOrigin = window.location.origin.replace("www.", "");

// Web URL: Production and Local
const autohubLocal = "http://localhost:3000";
export const autohubLive = "https://autohubksa.com";

// Base URL: dynamically pick URL for backend services
export const baseURL = Object.freeze({
  [autohubLocal]: "http://localhost:8080",
  "https://autohub-web.ddtestingserver.com": "https://autohub-cms.ddtestingserver.com",
  "https://web-autohub-dev-9f1462230d8f.herokuapp.com":
    "https://cms-autohub-dev-6a7bb31802aa.herokuapp.com",
  "https://staging.autohubksa.com": "https://staging-cms.autohubksa.com",
  [autohubLive]: "https://cms.autohubksa.com"
});

// Contact us: page content keys
export const contactUsPageConfig = Object.freeze({
  "customer-care": {
    bannerImage: "careBanner",
    mainHeading: "careTitle",
    description: "careDescription",
    formTitle: "customerCare",
    referenceKey: "care"
  },
  "partner-with-autohub": {
    bannerImage: "marketingBanner",
    mainHeading: "marketingTitle",
    description: "marketingDescription",
    formTitle: "partnerWithAutohub",
    referenceKey: "marketing"
  },
  "join-autohub-family": {
    bannerImage: "careerBanner",
    mainHeading: "careerTitle",
    description: "careerDescription",
    formTitle: "joinAutohubFamily",
    referenceKey: "career"
  },
  "delete-account": {
    bannerImage: "deleteAccountBanner",
    mainHeading: "deleteAccountTitle",
    description: "deleteAccountDescription",
    formTitle: "customerDeleteAccount",
    referenceKey: "delete-account"
  },
  "signup-for-service": {
    bannerImage: "vipServiceBanner",
    mainHeading: "serviceSignupTitle",
    description: "serviceSignupDescription",
    formTitle: "serviceSignup",
    referenceKey: "service-signup"
  },
  "vehicle-checkin": {
    bannerImage: "vehicleCheckinBanner",
    mainHeading: "vehicleCheckinTitle",
    description: "vehicleCheckinDescription",
    formTitle: "vehicleCheckinDetails",
    referenceKey: "vehicle-checkin"
  }
});

// Global: requirements for accessing app
export const allowedDomains = [autohubLocal, autohubLive];
export const isAccessComponent = loadStorage("token") || allowedDomains.includes(domainOrigin);

// Global: payment methods
export const paymentCards = Object.freeze([
  { logo: VisaLogo, value: "VISA" },
  { logo: MasterLogo, value: "MASTER" },
  { logo: MadaLogo, value: "MADA" },
  { logo: tamaraLogoSet, value: "TAMARA" }
]);

// My account: menu active key config
export const accountMenuConfig = Object.freeze({
  "order-invoices": 2,
  subscription: 3
});

// Global: KSA country code
export const ksaCountryCode = "+966";

// Eshop order: my account state when landed via eshop
export const eShopToMyAccount = "eshopOrder";
