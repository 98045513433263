import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, LoginModal } from "./";
import { currentLanguage, isLtr, userLogout } from "../../util/common";
import { switchLanguage } from "../../util/layoutUtils";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../services";
import { useAppNavigation } from "../../hooks";

const Topbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = currentLanguage();
  const { navigate, localeRoutes, toBringIt } = useAppNavigation();

  const [isLoginModalOpen, setIsLoginModelOpen] = useState(false);
  const userData = useSelector((state) => state.user.details);

  const closeLoginModel = () => {
    setIsLoginModelOpen(false);
  };

  const openLoginModel = () => setIsLoginModelOpen(true);

  const logOut = async () => {
    if (location?.pathname === localeRoutes.appointment[lang]) toBringIt();
    await UserService.logout();
    userLogout(dispatch);
  };

  return (
    <section id="topbar" className="d-flex align-items-center sticky-top top-bar">
      <a
        href="https://wa.me/966920024420"
        className="whatsapp-icon"
        target="_blank"
        rel="noreferrer">
        <Icon iconName="whatsapp" />
      </a>
      <a href="tel:920024420" className="social-link">
        <Icon iconName="phone" />
        <span>{t("topbar.phoneNumber")}</span>
      </a>
      {userData.id ? (
        <>
          <Link to={localeRoutes.myAccount[lang]} className="my-account-btn">
            {t("topbar.myAccount")}
          </Link>
          <div className="logout-btn" title="Log out" onClick={logOut}>
            <Icon iconName="signOut" />
          </div>
        </>
      ) : (
        <div className="signin-login">
          <span className="login-btn" onClick={openLoginModel}>
            {t("topbar.loginText")}
          </span>
        </div>
      )}
      <div className="lang-box">
        <button onClick={() => switchLanguage(isLtr() ? "ar" : "en", navigate, location)}>
          {isLtr() ? "عربي" : "English"}
        </button>
      </div>

      <LoginModal show={isLoginModalOpen} onCancel={closeLoginModel} />
    </section>
  );
};

export default Topbar;
