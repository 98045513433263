import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { hotjar } from "react-hotjar";
import PagesService from "../../services/pages";
import { Button, Loader, Topbar, Icon } from "../shared";
import CampaignService from "../../services/campaign";
import { AutohubServices, UserService } from "../../services";
import {
  isLtr,
  scrollPageTop,
  camelize,
  navigationWithEvents,
  logEvent,
  currentLanguage,
  getQueryData
} from "../../util/common";
import { Link, useLocation } from "react-router-dom";
import { contactUs, logoSet } from "../../util/constants";
import { saveMedia } from "../../redux/reducers/contentReducer";
import { useDispatch, useSelector } from "react-redux";
import BottomTabNavigations from "./BottomTabNavigation";
import { handleLayout } from "../../util/layoutUtils";
import { vanishDraftValues } from "../../util/addonsUtils";
import { useAppNavigation, useWindowWide } from "../../hooks";
import { saveUser } from "../../redux/reducers/userReducer";

const Header = () => {
  const { t } = useTranslation();
  const lang = currentLanguage();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const wide = useWindowWide();
  const { user: userData, content } = useSelector((state) => state);
  const {
    toServices,
    toOffer,
    toContactUs,
    toTrackYourProgress,
    toEstimationWelcome,
    toShop,
    toBringIt,
    localeRoutes
  } = useAppNavigation();

  const user = userData.details;
  const pages = content.pages || [];
  const inService = pathname.includes(localeRoutes.services[lang]);
  const inOffer = pathname.includes(localeRoutes.offers[lang]);
  const inKaizen = pathname.includes(localeRoutes.kaizen[lang]);
  const knowledgeHub = pathname.includes(localeRoutes.knowledgeHub[lang]);

  const [navShow, setNavShow] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [services, setServices] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isOfferHasNewDeal, setIsOfferHasNewDeal] = useState(false);

  const slicedOffers = offers?.length > 5 ? offers?.slice(0, 4) : offers;

  useEffect(() => {
    getAppData();
    hotjar.initialize(2632337, 4.0);
  }, []);

  useEffect(() => {
    scrollPageTop();
    // if (wide < 991 && navShow) setNavShow(false); // Only update navShow if it's currently true
    if (wide < 991) setNavShow(false);
    vanishDraftValues();

    // Handle localization
    const pathParts = pathname.split("/");
    const language = pathParts[1] === "en" ? "en" : "ar";
    i18n.changeLanguage(language);
    handleLayout();
  }, [pathname]);

  const getAppData = async () => {
    setProcessing(true);
    try {
      await getUserNotifications();

      const media = await PagesService.getMedia();
      dispatch(saveMedia(media));

      const campaigns = await CampaignService.getCampaign();
      if (campaigns) {
        setOffers(campaigns?.filter((offer) => offer?.offerType !== "prePaid"));
        toCheckIfOneOfferIsNewDeal(campaigns);
      }

      const services = await AutohubServices.getServices({ filterKey: "isShowInService" });
      if (services) {
        const servicesCopy = services
          .flatMap((service) => service?.services || [])
          .filter((service) => !service?.isComingSoon);

        setServices(servicesCopy.slice(0, 6));
      } else setServices([]);
    } catch (e) {
      console.error(e);
    }
    setProcessing(false);
  };

  const getUserNotifications = async () => {
    const hyperpayId = getQueryData("id");
    if (!user.id || !user.notifications?.isCNDDocsSubmitted || hyperpayId) return;
    const { notifications } = await UserService.getUserNotifications();
    dispatch(saveUser({ ...user, notifications }));
  };

  const toCheckIfOneOfferIsNewDeal = (offers) => {
    return setIsOfferHasNewDeal(offers.some((offer) => offer?.isNewDeal === true));
  };

  return (
    <>
      <Loader show={isProcessing} />

      <Topbar />

      <header id="header" className="sticky-top">
        <div className="d-flex align-items-center justify-content-between bottom-bar">
          <div className="bottom-bar-left">
            <div className="logo">
              <Link
                to={localeRoutes.home[lang]}
                onClick={() => {
                  logEvent("Header", "logo_link_click", "Logo Link");
                }}
                className="cursor-pointer">
                <img src={logoSet[lang]} width="250px" height="100%" alt="autohub-logo" />
              </Link>
            </div>

            <nav
              id="navbar"
              className={
                navShow
                  ? "navbar order-last order-lg-0 navbar-mobile"
                  : "navbar order-last order-lg-0"
              }>
              <ul>
                <li className="mb-logo text-center">
                  <Link
                    to={localeRoutes.home[lang]}
                    onClick={() => {
                      logEvent("Header", "logo_link_click", "Logo Link");
                    }}
                    className="cursor-pointer">
                    <img src={logoSet[lang]} alt="autohub-logo" width="100%" height="100%" />
                  </Link>
                </li>
                {pages.includes("catalog") && (
                  <li className={`${pathname === localeRoutes.catalog[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.catalog[lang]}
                      onClick={() => {
                        logEvent("Header", "catalog_link_click", "calalog Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.catalog")}
                    </Link>
                  </li>
                )}
                {pages.includes("services") && (
                  <li className={`dropdown dropdown-area ${inService}`}>
                    <span className="nav-link scrollto dropdown-toggle" onClick={toServices}>
                      {t("header.ourServices")}
                      <Icon iconName="caretDown" />
                    </span>
                    <ul className="dropdown-menu">
                      {services?.map(({ _id, name }, i) => {
                        return (
                          <Link
                            key={i}
                            onClick={() => {
                              logEvent("Header", "service_link_click", `Service ID: ${_id} Link`);
                            }}
                            to={`${localeRoutes.services[lang]}/${_id}`}
                            className={`dropdown-item ${pathname.includes(_id)}`}>
                            {!isLtr() ? name.value_ar : name.value_en}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                )}
                {pages.includes("offers") && (
                  <li className={`dropdown dropdown-area ${inOffer}`}>
                    <span className="nav-link scrollto dropdown-toggle" onClick={toOffer}>
                      {t("header.offers")}
                      <Icon iconName="caretDown" />

                      {isOfferHasNewDeal ? (
                        <span className="offersNewTag headerNewTag">{t("offer.new")}</span>
                      ) : (
                        <></>
                      )}
                    </span>
                    <ul className="dropdown-menu">
                      {slicedOffers?.map(({ slugUrl, name, isNewDeal }, i) => {
                        const selectedOffer =
                          pathname.replace(`${localeRoutes.offers[lang]}/`, "") === slugUrl;
                        return (
                          <Link
                            key={i}
                            onClick={() => {
                              logEvent("Header", "offer_link_click", `Offer ID: ${slugUrl} Link`);
                            }}
                            to={`${localeRoutes.offers[lang]}/${slugUrl}`}
                            className={`dropdown-item ${selectedOffer}`}>
                            {name["value_" + lang]}{" "}
                            {isNewDeal ? (
                              <span className="offersNewTag">{t("offer.new")}</span>
                            ) : (
                              ""
                            )}
                          </Link>
                        );
                      })}
                      {offers?.length > 5 ? (
                        <Link
                          to={localeRoutes.offers[lang]}
                          onClick={() => {
                            logEvent("Header", "offer_link_click", "Offer Link");
                          }}
                          className="dropdown-item">
                          {t("header.seeMore")}
                        </Link>
                      ) : null}
                    </ul>
                  </li>
                )}
                {pages.includes("kaizen") && (
                  <li className={`${inKaizen} header-menu`}>
                    <Link
                      to={localeRoutes.kaizen[lang]}
                      onClick={() => {
                        logEvent("Header", "kaizen_link_click", "Kaizen Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.kaizen")}
                    </Link>
                  </li>
                )}
                {pages.includes("blogs") && (
                  <li className={`${knowledgeHub} header-menu`}>
                    <Link
                      to={localeRoutes.knowledgeHub[lang]}
                      onClick={() => {
                        logEvent("Header", "knowledgehub_link_click", "Knowledgehub Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.knowledgehub")}
                    </Link>
                  </li>
                )}
                {pages.includes("location") && (
                  <li className={`${pathname === localeRoutes.locations[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.locations[lang]}
                      onClick={() => {
                        logEvent("Header", "location_link_click", "Location Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.location")}
                    </Link>
                  </li>
                )}
                {pages.includes("contactus") && (
                  <li className={`dropdown dropdown-area ${contactUs.includes(pathname.slice(1))}`}>
                    <span className="nav-link scrollto dropdown-toggle">
                      {t("header.contactus")}
                      <Icon iconName="caretDown" onClick={(e) => toContactUs({ e })} />
                    </span>
                    <ul className="dropdown-menu">
                      <Link
                        to={localeRoutes.customerCare[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.customerCare[lang]}`}>
                        {t("header." + camelize(contactUs[0].replaceAll("-", " ")))}
                      </Link>
                      <Link
                        to={localeRoutes.becomeAPartner[lang]}
                        className={`dropdown-item ${
                          pathname === localeRoutes.becomeAPartner[lang]
                        }`}>
                        {t("header." + camelize(contactUs[1].replaceAll("-", " ")))}
                      </Link>
                      <Link
                        to={localeRoutes.joinFamily[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.joinFamily[lang]}`}>
                        {t("header." + camelize(contactUs[2].replaceAll("-", " ")))}
                      </Link>
                      {/* <Link
                        to={localeRoutes.deleteAccount[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.deleteAccount[lang]}`}>
                        {t("header." + camelize(contactUs[3].replaceAll("-", " ")))}
                      </Link> */}
                    </ul>
                  </li>
                )}
                {pages.includes("insurance") && (
                  <li className={`${pathname === localeRoutes.insurance[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.insurance[lang]}
                      onClick={() => {
                        logEvent("Header", "insurance_link_click", "Insurance Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.insurance")}
                    </Link>
                  </li>
                )}
                <li className="resp-bottom-bar-right">
                  <div className="bottom-bar-right">
                    {pages.includes("estimation") && (
                      <Button
                        type="small-roundedRight"
                        // customize="beta-tag"
                        action={() =>
                          navigationWithEvents(
                            "Header-Estimation",
                            "Estimation Welcome",
                            toEstimationWelcome
                          )
                        }>
                        {t("header.estimationRequest")}
                        {/* <span>{t("header.betaTag")}</span> */}
                      </Button>
                    )}
                    {pages.includes("schedule") && (
                      <Button
                        type="blue-roundedRight"
                        action={() =>
                          navigationWithEvents(
                            "Header-Schedule-Appointment",
                            "Schedule Appointment",
                            toBringIt
                          )
                        }>
                        {t("header.bringItToTheExpert")}
                      </Button>
                    )}
                    {pages.includes("track") && (
                      <Button
                        type="small-roundedRight"
                        action={() =>
                          navigationWithEvents(
                            "Header-Track",
                            "Track your progress",
                            toTrackYourProgress
                          )
                        }>
                        {t("header.trackYourRepairProgress")}
                      </Button>
                    )}
                    {pages.includes("eshop") && (
                      <Button
                        type="blue-roundedRight"
                        action={() => navigationWithEvents("Header-Shop", "Shop", toShop)}>
                        {t("header.shop")}
                      </Button>
                    )}
                  </div>
                </li>
              </ul>
              <Icon
                iconName={navShow ? "xmark" : "bars"}
                className={"mobile-nav-" + `${navShow ? "close" : "toggle"}`}
                onClick={() => setNavShow(!navShow)}
              />
            </nav>
          </div>

          <div className="bottom-bar-right">
            {pages.includes("estimation") && (
              <Button
                type="small-roundedRight"
                customize="beta-tag"
                action={() =>
                  navigationWithEvents(
                    "Header-Estimation",
                    "Estimation Welcome",
                    toEstimationWelcome
                  )
                }>
                {t("header.estimationRequest")}
                {/* <span>{t("header.betaTag")}</span> */}
              </Button>
            )}
            {pages.includes("schedule") && (
              <Button
                type="blue-roundedRight"
                action={() =>
                  navigationWithEvents(
                    "Header-Schedule-Appointment",
                    "Schedule Appointment",
                    toBringIt
                  )
                }>
                {t("header.bringItToTheExpert")}
              </Button>
            )}
            {pages.includes("track") && (
              <Button
                type="small-roundedRight"
                action={() =>
                  navigationWithEvents("Header-Track", "Track your progress", toTrackYourProgress)
                }>
                {t("header.trackYourRepairProgress")}
              </Button>
            )}
            {pages.includes("eshop") && (
              <Button
                type="blue-roundedRight"
                action={() => navigationWithEvents("Header-Shop", "Shop", toShop)}>
                {t("header.shop")}
              </Button>
            )}
          </div>

          {/* mobile responsive bottom nav */}
          <BottomTabNavigations />
        </div>
      </header>
    </>
  );
};

export default Header;
