import React from "react";
import { connect } from "formik";

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);

      if (errorElement) {
        let validationDivElement = document.getElementById("Error" + keys[0]);
        if (validationDivElement) {
          validationDivElement.scrollIntoView({
            behavior: "auto",
            block: "center"
            // inline: "center"
          });
        }

        errorElement.focus();
      } else {
        let validationDivElement = document.getElementById("Error" + keys[0]);
        if (validationDivElement) {
          validationDivElement.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center"
          });
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
