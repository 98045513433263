import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

const SharedUrlButtons = ({ sharedUrl = "" }) => {
  return (
    <>
      <WhatsappShareButton url={sharedUrl}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <FacebookShareButton url={sharedUrl}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={sharedUrl}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <LinkedinShareButton url={sharedUrl}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </>
  );
};

export default SharedUrlButtons;
